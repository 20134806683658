<template lang="html">
  <div class="card-cart-list-product">
    <div class="wrap-card-cart-list-product">
      <div class="card-cart-check-product">
        <v-checkbox v-model="item.checked" hide-details color="#1685C7" class="pa-0 ma-0" @change="changeChecked($event, index)" />
      </div>
      <div class="card-cart-content-product">
        <div class="card-cart-title-product">
          {{ item.name }}
        </div>
        <div class="card-cart-content-item">
          <div class="card-cart-content-item-image" @click="click(item, index)">
            <img :src="item.image" :alt="item.name">
          </div>
          <div class="card-cart-content-item-content" @click="click(item, index)">
            <div v-if="item.product_variant_id !== null" class="card-cart-content-item-detail">
              <span>{{ item.product_variant_name }}</span> <span> &#x2022; {{ itemWeight }} kg</span>
            </div>
            <div v-else class="card-cart-content-item-detail">
              {{ itemWeight }} kg
            </div>
            <div class="card-cart-content-item-price">
              <span v-mask-money="item.sell_price" />
              <span v-if="Number(item.market_price) > 0" v-mask-money-no-currency="item.market_price" class="market-price" />
            </div>
            <div v-if="item.loyalty_points !== 0 && redeemPointType === 0" class="card-cart-content-point">
              +{{ Number(item.loyalty_points) }} points
            </div>
          </div>
          <div v-if="$vuetify.breakpoint.width > 425" class="card-cart-content-item-action">
            <div class="card-cart-content-qty">
              <div class="input-plus-minus-qty">
                <v-text-field v-model="item.qty" hide-details dense outlined oninput="if(!this.value) this.value = 0;" @keydown="$filterInputNumber" @input="changeQty($event, index)">
                  <template v-slot:append-outer>
                    <v-btn icon small @click="plus(index)">
                      <v-icon>
                        mdi-plus
                      </v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:prepend>
                    <v-btn icon small @click="minus(index)">
                      <v-icon>
                        mdi-minus
                      </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </div>
            </div>
          </div>
        </div>
        <div v-if="$vuetify.breakpoint.width < 426" class="card-cart-content-item-action-self">
          <div class="card-cart-content-qty">
            <div class="input-plus-minus-qty">
              <v-text-field v-model="item.qty" hide-details dense outlined oninput="if(!this.value) this.value = 0;" @keydown="$filterInputNumber" @input="changeQty($event, index)">
                <template v-slot:append-outer>
                  <v-btn icon small @click="plus(index)">
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
                <template v-slot:prepend>
                  <v-btn icon small @click="minus(index)">
                    <v-icon>
                      mdi-minus
                    </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      required: true,
      type: Object
    },
    index: {
      required: true,
      type: Number
    },
    redeemPointType: {
      type: Number,
      default: null
    }
  },
  computed: {
    itemWeight() {
      // Accumulation Weight
      const item = this.$props.item
      const calcWeight = Math.round((item.weight * item.qty) * 10) / 10
      return calcWeight
    }
  },
  methods: {
    plus(idx) {
      this.$emit('plus', idx)
    },
    minus(idx) {
      this.$emit('minus', idx)
    },
    changeQty(e, idx) {
      this.$emit('changeQty', {
        idx: idx,
        value: Number(e)
      })
    },
    changeChecked(e, idx) {
      this.$emit('clickcheck', e, idx)
    },
    click(item, index) {
      this.$emit('click', item, index)
    }
  }
}
</script>
