<template>
  <div class="item-branch-store" :class="_handleStoreClose ? _fstoreStatus._CLOSE : ''" @click="onClick">
    <div class="item-branch-store__inner">
      <div class="item-branch-store__left">
        <v-img class="item-branch-store__left-img" :src="logo || imgStore" alt="logo" />
      </div>
      <div class="item-branch-store__desc">
        <span class="item-branch-store__desc-name text-ellipsis-1">{{ storeName }}</span>
        <span class="item-branch-store__desc-time text-ellipsis-2">{{ storeTime }}</span>
        <span class="item-branch-store__desc-address">{{ storeDistance }} &#x2022; {{ storeAddress }}</span>
      </div>
      <div class="item-branch-store__right">
        <div v-if="storeStatus === _fstoreStatus._OPEN" class="tag-open">
          {{ $t('layout.open') }}
        </div>
        <div v-if="storeStatus === _fstoreStatus._CLOSE" class="tag-close">
          {{ $t('layout.close') }}
        </div>
        <div v-if="storeStatus === _fstoreStatus._OPENING" class="tag-close">
          {{ $t('layout.open_soon') }}
        </div>
        <div v-if="storeStatus === _fstoreStatus._CLOSING" class="tag-open close-soon">
          {{ $t('layout.close_soon') }}
        </div>
        <div v-if="storeStatus === _fstoreStatus._OFF" class="tag-close">
          {{ $t('layout.store_off') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _imgStore from '@/assets/img/png/store.png'
import { FSTORE_STATUS, handleStoreClose } from '@/utils/storeOperational'

export default {
  name: 'ItemListBranchStore',
  props: {
    logo: {
      type: String,
      default: ''
    },
    storeName: {
      type: String,
      default: 'Store Name'
    },
    storeTime: {
      type: String,
      default: '00:00'
    },
    storeDistance: {
      type: String,
      default: '0m'
    },
    storeAddress: {
      type: String,
      default: ''
    },
    storeStatus: {
      type: String, // open, close, opening, closing, off
      default: 'close'
    },
    onClick: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      imgStore: _imgStore
    }
  },
  computed: {
    _fstoreStatus() {
      return FSTORE_STATUS
    },
    _handleStoreClose() {
      return handleStoreClose(this.storeStatus)
    }
  }
}
</script>

<style scoped lang="scss">
  .item-branch-store {
    padding: 0 16px;
    cursor: pointer;
    &__inner {
      display: flex;
      padding: 8px 0;
      gap: 8px;
      border-bottom: 1px solid #CECECE;
      align-items: center;
    }
    &__left {
      &-img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
      }
    }
    &__desc {
      flex: 1;
      display: flex;
      flex-direction: column;
      &-name {
        font-size: 14px;
        font-weight: 600;
      }
      &-time {
        font-size: 12px;
        font-weight: 400;
        color: #767676;
      }
      &-address {
        font-size: 12px;
        font-weight: 400;
        color: #767676;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: pre-wrap;
      }
    }
  }
  .item-branch-store.close {
    .item-branch-store__desc {
      &-name,&-time,&-address {
        color: #2727273D;
      }
    }
  }
</style>
