<template>
  <div v-if="!$isEmpty(selectedOutlet)">
    <span v-if="selectedOutlet.fstore_status === _fstoreStatus._OFF">
      <span :class="_classTextHighlight.storeOff">{{ $t('layout.store_off') }}</span>
      ({{ $t('layout.open') }} {{ getWorkingHour().workingDay }}{{ getWorkingHour().workingStartTime ? `, ${getWorkingHour().workingStartTime}` : '' }})
    </span>
    <span v-else-if="selectedOutlet.fstore_status === _fstoreStatus._OPEN">
      <span :class="_classTextHighlight.storeOpen">{{ $t('layout.open') }}</span>
      {{ $t('layout.until') }} {{ getWorkingHour().workingEndTime || '' }}
    </span>
    <span v-else-if="selectedOutlet.fstore_status === _fstoreStatus._CLOSING">
      <span :class="_classTextHighlight.storeClosing">{{ $t('layout.close_soon') }}</span>
      {{ getWorkingHour().workingEndTime || '' }}
    </span>
    <span v-else-if="selectedOutlet.fstore_status === _fstoreStatus._OPENING">
      <span :class="_classTextHighlight.storeOpening">{{ $t('layout.open_soon') }}</span>
      {{ getWorkingHour().workingStartTime || '' }}
    </span>
    <span v-else>
      <span :class="_classTextHighlight.storeClose">{{ $t('layout.close') }}</span>
      ({{ $t('layout.open') }} {{ getWorkingHour().workingDay }}{{ getWorkingHour().workingStartTime ? `, ${getWorkingHour().workingStartTime}` : '' }})
    </span>
  </div>
  <div v-else>
    <span :class="_classTextHighlight.storeClose">{{ $t('layout.close') }}</span>
  </div>
</template>

<script>
import { formatingDateToDay } from '@/utils/helpers'
import { FSTORE_STATUS, getNextWorkTime } from '@/utils/storeOperational'

const VariantEnum = {
  VARIANT_1: 'variant-1',
  VARIANT_2: 'variant-2'
}

export default {
  name: 'StoreStatus',
  props: {
    variant: {
      type: String,
      default: VariantEnum.VARIANT_1,
      validator: function(value) {
        return Object.values(VariantEnum).includes(value)
      }
    }
  },
  computed: {
    selectedOutlet() {
      return this.$store.getters.selectedOutlet
    },
    _fstoreStatus() {
      return FSTORE_STATUS
    },
    _classTextHighlight() {
      if (this.$props.variant === VariantEnum.VARIANT_1) {
        return {
          storeOff: 'font-weight-bold isClose',
          storeOpen: 'font-weight-bold isOpen',
          storeClosing: 'font-weight-bold isClosing',
          storeOpening: 'font-weight-bold isClose',
          storeClose: 'font-weight-bold isClose'
        }
      }
      if (this.$props.variant === VariantEnum.VARIANT_2) {
        return {
          storeOff: 'font-weight-bold',
          storeOpen: 'font-weight-bold',
          storeClosing: 'font-weight-bold',
          storeOpening: 'font-weight-bold',
          storeClose: 'font-weight-bold'
        }
      }
      return {
        storeOff: 'font-weight-bold',
        storeOpen: 'font-weight-bold',
        storeClosing: 'font-weight-bold',
        storeOpening: 'font-weight-bold',
        storeClose: 'font-weight-bold'
      }
    }
  },
  methods: {
    getWorkingHour() {
      const _getWorkTime = getNextWorkTime(this.selectedOutlet.store_open_date_tomorrow, this.selectedOutlet.store_closing_date_today)
      const workingStartTime = _getWorkTime.times[0].fstart_time
      const workingEndTime = _getWorkTime.times[0].fend_time
      const workingDay = formatingDateToDay(_getWorkTime.date)

      return {
        workingStartTime,
        workingEndTime,
        workingDay
      }
    }
  }
}
</script>
