<template lang="html">
  <div v-show="show">
    <v-expand-transition>
      <div :class="[`new-order`, {'pending' : order?.status === 'P' }]">
        <v-container>
          <v-row :class="[{ 'ma-0 fill-height': $vuetify.breakpoint.width > 600 }]">
            <v-col cols="12" :class="[{ 'pa-0': $vuetify.breakpoint.width > 600 }]">
              <v-row class="new-order-content" @click="handleToOrderDetail(order.id)">
                <v-col cols="6" sm="7" md="8">
                  <div class="new-order-items text-ellipsis-1">
                    <div v-for="(item, idx) in order?.items" :key="idx">
                      {{ item.item_name }}
                    </div>
                  </div>
                  <div class="new-order-total">
                    Total {{ order?.ftotal_amount }}
                  </div>
                </v-col>
                <v-col cols="6" sm="5" md="4">
                  <div :class="[`white--text new-order-status`, {'pending' : order?.status === 'P', 'processed_inactive': order.remark === 'DELIVERY' && order.shipping_delivery_type === '2' && order.status_mark === 'processed' && order.shipping_log.shipper.status === 'inactive' }]">
                    <v-icon :size="16" color="white">
                      <template v-if="order.status_mark === 'waiting_for_payment' || order.status_mark === 'waiting_confirmation_resto'">
                        mdi-information-variant
                      </template>
                      <template v-else-if="order.remark === 'DELIVERY' && order.shipping_delivery_type === '2' && order.status_mark === 'processed' && order.shipping_log.shipper.status === 'inactive'">
                        mdi-alert-outline
                      </template>
                      <template v-else-if="order.status_mark === 'processed'">
                        mdi-reload
                      </template>
                      <template v-else-if="order.status_mark === 'shipping'">
                        mdi-truck
                      </template>
                      <template v-else-if="order.status_mark === 'ready_to_pickup'">
                        mdi-store
                      </template>
                      <template v-else-if="order.status_mark === 'received' || order.status_mark === 'picked_up' || order.status_mark === 'shipped'">
                        mdi-package-variant-closed
                      </template>
                      <template v-else-if="order.status_mark === 'completed'" :size="16">
                        mdi-check-circle
                      </template>
                      <template v-else-if="order.status_mark === 'cancelled' || order.status_mark === 'not_documented_status'" :size="16">
                        mdi-close
                      </template>
                    </v-icon>
                    <div class="text-ellipsis-2">
                      {{ order?.status_desc }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-expand-transition>
  </div>
</template>
<script>
export default {
  name: 'NewOrder',
  props: {
    value: {
      default: false,
      type: Boolean
    },
    order: {
      type: Object,
      default: null
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    handleToOrderDetail(id) {
      this.$router.push({ name: 'order', params: { id: id }}).catch(() => {})
    }
  }
}
</script>
