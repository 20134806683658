<template>
  <div class="card-border">
    <div class="d-flex align-center">
      <div class="font-18 font-weight-bold ml-2">
        {{ orderStatus }}
      </div>
      <v-spacer />
      <v-btn color="primary" height="40" class="text-none font-16 border-radius-5 font-weight-bold letter-space-0 white--text" @click="handleCall">
        {{ $t('order.contact_seller') }}
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OutletCallCard',
  props: {
    orderStatus: {
      default: '',
      type: String
    }
  },
  methods: {
    handleCall() {
      this.$emit('handle-call')
    }
  }
}
</script>
