import axios from 'axios'
import { store } from '@/store'
import { getToken } from '@/utils/auth'
import subtractMinutes from 'date-fns/subMinutes'
import i18n from '@/plugins/i18n'

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL, // api
  timeout: 20000, // request timeout,
  globalLoading: true, // tambahan untuk mengaktifkan global loading, default true dikarenakan sudah banyak yg menggunakan global loading tersebut. jika ingin mematikan maka nonaktifkan menjadi false.
  globalParamUrlId: true
})

request.interceptors.request.use(config => {
  const token = getToken()
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }
  if (config.globalLoading) {
    store.dispatch('showLoading', true)
  }
  // Mengakses nilai useUrlId dari config
  const globalParamUrlId = config.globalParamUrlId
  // Menetapkan nilai url_id ke params jika useUrlId bernilai true
  if (globalParamUrlId) {
    const selectedOutlet = store.getters.selectedOutlet
    config.params = {
      url_id: selectedOutlet.url_id,
      ...config.params
    }
  }
  return config
}, error => {
  return Promise.reject(error)
})

request.interceptors.response.use(response => {
  store.dispatch('showLoading', false)
  return response
}, async(error) => {
  store.dispatch('showLoading', false)
  let statusCode = 500
  let message = i18n.t('global.error_message_connection_time_out')
  let string = i18n.t('global.error_message_connection_time_out')

  // Jika terjadi request time out, ini yang akan di handle pertama kali
  if (error.code === 'ECONNABORTED') {
    return Promise.reject({
      statusCode,
      message,
      string,
      data: {},
      error_tagging: 'ECONNABORTED'
    })
  }

  const errorError = error.response.data.error.error
  let errArr = errorError
  string = errArr
  if (typeof errorError !== 'string') {
    string = ''
    errArr = Object.values(errorError)
    for (let index = 0; index < errArr.length; index++) {
      string += '- ' + errArr[index]
    }
  }
  const data = error.response.data.error.data

  const error_tagging = error.response.data.error.error_tagging
  statusCode = error.response.data.error.status_code
  message = error.response.data.error.message

  var tokenExpiryDate = null
  var tenMinutesBeforeExpiry = 0
  var now = Date.now()
  if (typeof store.state.global.auth_data.expires_time !== 'undefined') {
    tokenExpiryDate = Date.parse(store.state.global.auth_data.expires_time)
    tenMinutesBeforeExpiry = Date.parse(subtractMinutes(tokenExpiryDate, 30))
  }

  /**
   * Auto redirect if error status 302 (toko dibekukan)
   */
  if (statusCode === 302) {
    await store.dispatch('remove_token')
    localStorage.clear()
    window.location.replace(error.response.data.error.redirect_url)
    return Promise.reject({
      statusCode,
      message,
      string,
      data,
      error_tagging
    })
  }

  if (statusCode === 401) {
    if (!tokenExpiryDate || now > tenMinutesBeforeExpiry) {
      if (store.state.global.auth_data !== null) {
        if (store.state.global.auth_data.auth_by === 'BASIC' || store.state.global.auth_data.auth_by === 'SYSTEM') {
          if (store.state.auth_data.refresh_token) {
            const original_request = error.config
            original_request._retry = true
            const headers = {
              'Authorization': 'Bearer' + getToken()
            }
            const dataAuth = {
              client_id: process.env.VUE_APP_CLIENT_ID,
              client_secret: process.env.VUE_APP_CLIENT_SECRET,
              grant_type: 'refresh_token',
              refresh_token: store.state.auth_data.refresh_token,
              scope: ''
            }
            return new Promise((resolve, reject) => {
              if (store.state.auth_data.auth_by === 'BASIC') {
                axios.post('/customer/auth/refreshtoken', dataAuth, { headers: headers }).then(res => {
                  store.dispatch('save_token', res.data)
                  store.state.loading_axios = false
                  error.config.headers['Authorization'] = 'Bearer' + res.data.access_token
                  return axios.request(error.config)
                }).catch(async() => {
                  localStorage.removeItem(`${process.env.VUE_APP_COOKIE_KEY}_selected_branch`)
                  await store.dispatch('fetchToken')
                  await store.dispatch('fetchStore')
                  const is_refresh_token = 1
                  reject({
                    statusCode,
                    message,
                    string,
                    data,
                    is_refresh_token
                  })
                })
              } else {
                axios.get('/system/refreshtoken', { headers: headers }).then(res => {
                  store.dispatch('save_token', res.data)
                  store.state.loading_axios = false
                  error.config.headers['Authorization'] = 'Bearer' + res.data.access_token
                  location.reload()
                  return axios.request(error.config)
                }).catch(async() => {
                  localStorage.removeItem(`${process.env.VUE_APP_COOKIE_KEY}_selected_branch`)
                  await store.dispatch('fetchToken')
                  await store.dispatch('fetchStore')
                  const is_refresh_token = 1
                  reject({
                    statusCode,
                    message,
                    string,
                    data,
                    is_refresh_token
                  })
                })
              }
            })
          } else {
            store.dispatch('fetchToken')
          }
        } else {
          await store.dispatch('remove_token')
          await store.dispatch('fetchToken')
          await store.dispatch('fetchStore')
          location.reload()
        }
      } else {
        await store.dispatch('fetchToken')
      }
    }
  }

  return Promise.reject({
    statusCode,
    message,
    string,
    data,
    error_tagging
  })
})

export { request }
