import Vue from 'vue'

Vue.prototype.$isEmpty = function(obj) {
  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false
    }
  }
  return true
}

Vue.prototype.$sum = function(items, prop) {
  return items.reduce(function(a, b) {
    return a + b[prop]
  }, 0)
}

Vue.prototype.$stockCart = function(id, type = 'product') {
  var qty = 0
  var cart = {
    item: []
  }
  const LOCAL_DATA_CART = localStorage.getItem(process.env.VUE_APP_COOKIE_KEY + '_data_cart')
  if (LOCAL_DATA_CART) {
    cart = JSON.parse(LOCAL_DATA_CART)
    if (cart.item.length > 0) {
      for (var i = 0; i < cart.item.length; i++) {
        if (type === 'variant') {
          if (cart.item[i].product_variant_id && Number(cart.item[i].product_variant_id) === Number(id)) {
            qty = qty + Number(cart.item[i].qty)
          }
          if (cart.item[i].product_combo_id) {
            for (var y = 0; y < cart.item[i].product_combo_items.length; y++) {
              if (cart.item[i].product_combo_items[y].variant_id && Number(cart.item[i].product_combo_items[y].variant_id) === Number(id)) {
                qty = qty + (Number(cart.item[i].qty) * Number(cart.item[i].product_combo_items[y].qty))
              }
            }
          }
          if (cart.item[i].materials.length > 0) {
            for (let c = 0; c < cart.item[i].materials.length; c++) {
              const material = cart.item[i].materials[c]
              if (material.material_product_variant_id && Number(material.material_product_variant_id) === Number(id)) {
                qty = qty + Number(material.qty_in_cart)
              }
            }
          }
        } else {
          if (cart.item[i].product_id && Number(cart.item[i].product_id) === Number(id)) {
            qty = qty + Number(cart.item[i].qty)
          }
          if (cart.item[i].product_combo_id) {
            for (var z = 0; z < cart.item[i].product_combo_items.length; z++) {
              if (cart.item[i].product_combo_items[z].id && Number(cart.item[i].product_combo_items[z].id) === Number(id)) {
                qty = qty + (Number(cart.item[i].qty) * Number(cart.item[i].product_combo_items[z].qty))
              }
            }
          }
          if (cart.item[i].materials.length > 0) {
            for (let c = 0; c < cart.item[i].materials.length; c++) {
              const material = cart.item[i].materials[c]
              if (material.material_product_id && Number(material.material_product_id) === Number(id)) {
                qty = qty + Number(material.qty_in_cart)
              }
            }
          }
        }
      }
    }
  }
  return qty
}

/**
 *
 * @param {*} detail_product data dari API detail product
 * @param {*} productVariantId data variant yang terpilih
 * @returns
 */
Vue.prototype.$getProductPrice = function(detail_product, type = 'sell_price', productVariantId = null) {
  if (!detail_product) {
    return 0
  }
  let price = detail_product[type]
  if (productVariantId) {
    const idx = detail_product.variants.findIndex((e) => e.id === productVariantId)
    if (idx !== -1) {
      price = detail_product.variants[idx][type]
    }
  } else {
    if (detail_product.variants && detail_product.variants.length > 0) {
      const idx = detail_product.variants.findIndex((e) => e.is_default === 1)
      if (idx !== -1) {
        price = detail_product.variants[idx][type]
      } else {
        price = detail_product.variants[0][type]
      }
    }
  }
  return Number(price)
}

/**
 *
 * @param {*} product data dari API detail product
 * @returns
 */
Vue.prototype.$getCommodity = function(product) {
  var data_commodity = []
  if (product.items) {
    product.items.map((item, idx) => {
      data_commodity.push([])
      if (item.children.length !== 0) {
        if (item.product_has_variant) {
          if (item.product_track_inventory) {
            if (item.variant.length > 0 && item.product_variant_id === 0) {
              for (var a in item.variant) {
                if (!item.variant[a].is_out_stock) {
                  const stock_incart = Vue.prototype.$stockCart(item.product_variant_id, 'variant')
                  if (Number(item.variant[a].stock_qty) - Number(item.variant[a].hold_qty) >= (Number(item.qty) + stock_incart)) {
                    data_commodity[idx].push({
                      id: item.id,
                      variant_id: item.variant[a].id,
                      name: item.product_name,
                      variant_name: item.variant[a].name,
                      image: item.variant[a].photo_md,
                      disabled: false,
                      qty: Number(item.qty),
                      stock_qty: Number(item.variant[a].stock_qty),
                      hold_qty: Number(item.variant[a].hold_qty),
                      is_out_stock: item.variant[a].is_out_stock,
                      track_inventory: item.product_track_inventory,
                      published: item.published,
                      weight: Number(item.variant[a].vweight)
                    })
                  } else {
                    data_commodity[idx].push({
                      id: item.id,
                      variant_id: item.variant[a].id,
                      name: item.product_name,
                      variant_name: item.variant[a].name,
                      image: item.variant[a].photo_md,
                      disabled: true,
                      qty: Number(item.qty),
                      stock_qty: Number(item.variant[a].stock_qty),
                      hold_qty: Number(item.variant[a].hold_qty),
                      is_out_stock: item.variant[a].is_out_stock,
                      track_inventory: item.product_track_inventory,
                      published: item.published,
                      weight: Number(item.variant[a].vweight)
                    })
                  }
                } else {
                  data_commodity[idx].push({
                    id: item.id,
                    variant_id: item.variant[a].id,
                    name: item.product_name,
                    variant_name: item.variant[a].name,
                    image: item.variant[a].photo_md,
                    disabled: true,
                    qty: Number(item.qty),
                    stock_qty: Number(item.variant[a].stock_qty),
                    hold_qty: Number(item.variant[a].hold_qty),
                    is_out_stock: item.variant[a].is_out_stock,
                    track_inventory: item.product_track_inventory,
                    published: item.published,
                    weight: Number(item.variant[a].vweight)
                  })
                }
              }
            } else {
              const result = item.variant.find(obj => {
                return obj.id === item.product_variant_id
              })
              if (typeof result !== 'undefined') {
                if (!result.is_out_stock) {
                  const stock_incart = Vue.prototype.$stockCart(result.id, 'variant')
                  if (Number(result.stock_qty) - Number(result.hold_qty) >= (Number(item.qty) + stock_incart)) {
                    data_commodity[idx].push({
                      id: item.id,
                      variant_id: item.product_variant_id,
                      name: item.product_name,
                      variant_name: result.name,
                      image: result.photo_md,
                      disabled: false,
                      qty: Number(item.qty),
                      stock_qty: Number(result.stock_qty),
                      hold_qty: Number(result.hold_qty),
                      is_out_stock: result.is_out_stock,
                      track_inventory: item.product_track_inventory,
                      published: item.published,
                      weight: Number(result.vweight)
                    })
                  } else {
                    data_commodity[idx].push({
                      id: item.id,
                      variant_id: item.product_variant_id,
                      name: item.product_name,
                      variant_name: result.name,
                      image: result.photo_md,
                      disabled: true,
                      qty: Number(item.qty),
                      stock_qty: Number(result.stock_qty),
                      hold_qty: Number(result.hold_qty),
                      is_out_stock: result.is_out_stock,
                      track_inventory: item.product_track_inventory,
                      published: item.published,
                      weight: Number(result.vweight)
                    })
                  }
                } else {
                  data_commodity[idx].push({
                    id: item.id,
                    variant_id: item.product_variant_id,
                    name: item.product_name,
                    variant_name: result.name,
                    image: result.photo_md,
                    disabled: true,
                    qty: Number(item.qty),
                    stock_qty: Number(result.stock_qty),
                    hold_qty: Number(result.hold_qty),
                    is_out_stock: result.is_out_stock,
                    track_inventory: item.product_track_inventory,
                    published: item.published,
                    weight: Number(result.vweight)
                  })
                }
              }
            }
          } else {
            if (item.variant.length > 0 && item.product_variant_id === 0) {
              for (var b in item.variant) {
                data_commodity[idx].push({
                  id: item.id,
                  variant_id: item.variant[b].id,
                  name: item.product_name,
                  variant_name: item.variant[b].name,
                  image: item.variant[b].photo_md,
                  disabled: false,
                  qty: Number(item.qty),
                  stock_qty: Number(item.variant[b].stock_qty),
                  hold_qty: Number(item.variant[b].hold_qty),
                  is_out_stock: item.variant[b].is_out_stock,
                  track_inventory: item.product_track_inventory,
                  published: item.published,
                  weight: Number(item.variant[b].vweight)
                })
              }
            } else {
              const result = item.variant.find(obj => {
                return obj.id === item.product_variant_id
              })
              data_commodity[idx].push({
                id: item.id,
                variant_id: item.product_variant_id,
                name: item.product_name,
                variant_name: result.name,
                image: result.photo_md,
                disabled: false,
                qty: Number(item.qty),
                stock_qty: Number(result.stock_qty),
                hold_qty: Number(result.hold_qty),
                is_out_stock: result.is_out_stock,
                track_inventory: item.product_track_inventory,
                published: item.published,
                weight: Number(result.vweight)
              })
            }
          }
        } else {
          if (!item.product_is_out_stock) {
            const stock_incart = Vue.prototype.$stockCart(item.product_id)
            if (item.product_track_inventory) {
              if (Number(item.product_stock_qty) - Number(item.product_hold_qty) >= (Number(item.qty) + stock_incart)) {
                data_commodity[idx].push({
                  id: item.id,
                  variant_id: '',
                  name: item.product_name, /* tuna 1 */
                  variant_name: '',
                  image: item.photo_md,
                  disabled: false,
                  qty: Number(item.qty),
                  stock_qty: Number(item.stock_qty),
                  hold_qty: Number(item.hold_qty),
                  is_out_stock: item.product_is_out_stock,
                  track_inventory: item.product_track_inventory,
                  published: item.published,
                  weight: Number(item.weight)
                })
              } else {
                data_commodity[idx].push({
                  id: item.id,
                  variant_id: '',
                  name: item.product_name,
                  variant_name: '',
                  image: item.photo_md,
                  disabled: true,
                  qty: Number(item.qty),
                  stock_qty: Number(item.stock_qty),
                  hold_qty: Number(item.hold_qty),
                  is_out_stock: item.product_is_out_stock,
                  track_inventory: item.product_track_inventory,
                  published: item.published,
                  weight: Number(item.weight)
                })
              }
            } else {
              data_commodity[idx].push({
                id: item.id,
                variant_id: '',
                name: item.product_name,
                variant_name: '',
                image: item.photo_md,
                disabled: false,
                qty: Number(item.qty),
                stock_qty: Number(item.stock_qty),
                hold_qty: Number(item.hold_qty),
                is_out_stock: item.product_is_out_stock,
                track_inventory: item.product_track_inventory,
                published: item.published,
                weight: Number(item.weight)
              })
            }
          }
        }
        for (var i in item.children) {
          if (item.children[i].product_has_variant) {
            if (item.children[i].product_track_inventory) {
              if (item.children[i].variant.length > 0 && item.children[i].product_variant_id === 0) {
                for (var x in item.children[i].variant) {
                  if (!item.children[i].variant[x].is_out_stock) {
                    const stock_incart = Vue.prototype.$stockCart(item.children[i].variant[x].id, 'variant')
                    if (Number(item.children[i].variant[x].stock_qty) - Number(item.children[i].variant[x].hold_qty) >= (Number(item.children[i].qty) + stock_incart)) {
                      data_commodity[idx].push({
                        id: item.children[i].id,
                        variant_id: item.children[i].variant[x].id,
                        name: item.children[i].product_name,
                        variant_name: item.children[i].variant[x].name,
                        image: item.children[i].variant[x].photo_md,
                        disabled: false,
                        qty: Number(item.children[i].qty),
                        stock_qty: Number(item.children[i].variant[x].stock_qty),
                        hold_qty: Number(item.children[i].variant[x].hold_qty),
                        is_out_stock: item.children[i].variant[x].is_out_stock,
                        track_inventory: item.children[i].product_track_inventory,
                        published: item.children[i].published,
                        weight: Number(item.children[i].variant[x].vweight)
                      })
                    } else {
                      data_commodity[idx].push({
                        id: item.children[i].id,
                        variant_id: item.children[i].variant[x].id,
                        name: item.children[i].product_name,
                        variant_name: item.children[i].variant[x].name,
                        image: item.children[i].variant[x].photo_md,
                        disabled: true,
                        qty: Number(item.children[i].qty),
                        stock_qty: Number(item.children[i].variant[x].stock_qty),
                        hold_qty: Number(item.children[i].variant[x].hold_qty),
                        is_out_stock: item.children[i].variant[x].is_out_stock,
                        track_inventory: item.children[i].product_track_inventory,
                        published: item.children[i].published,
                        weight: Number(item.children[i].variant[x].vweight)
                      })
                    }
                  } else {
                    data_commodity[idx].push({
                      id: item.children[i].id,
                      variant_id: item.children[i].variant[x].id,
                      name: item.children[i].product_name,
                      variant_name: item.children[i].variant[x].name,
                      image: item.children[i].variant[x].photo_md,
                      disabled: true,
                      qty: Number(item.children[i].qty),
                      stock_qty: Number(item.children[i].variant[x].stock_qty),
                      hold_qty: Number(item.children[i].variant[x].hold_qty),
                      is_out_stock: item.children[i].variant[x].is_out_stock,
                      track_inventory: item.children[i].product_track_inventory,
                      published: item.children[i].published,
                      weight: Number(item.children[i].variant[x].vweight)
                    })
                  }
                }
              } else {
                const result = item.children[i].variant.find(obj => {
                  return obj.id === item.children[i].product_variant_id
                })
                if (typeof result !== 'undefined') {
                  if (!result.is_out_stock) {
                    const stock_incart = Vue.prototype.$stockCart(result.id, 'variant')
                    if (Number(result.stock_qty) - Number(result.hold_qty) >= (Number(item.children[i].qty) + stock_incart)) {
                      data_commodity[idx].push({
                        id: item.children[i].id,
                        variant_id: item.children[i].product_variant_id,
                        name: item.children[i].product_name,
                        variant_name: result.name,
                        image: result.photo_md,
                        disabled: false,
                        qty: Number(item.children[i].qty),
                        stock_qty: Number(result.stock_qty),
                        hold_qty: Number(result.hold_qty),
                        is_out_stock: result.is_out_stock,
                        track_inventory: item.children[i].product_track_inventory,
                        published: item.children[i].published,
                        weight: Number(result.vweight)
                      })
                    } else {
                      data_commodity[idx].push({
                        id: item.children[i].id,
                        variant_id: item.children[i].product_variant_id,
                        name: item.children[i].product_name,
                        variant_name: result.name,
                        image: result.photo_md,
                        disabled: true,
                        qty: Number(item.children[i].qty),
                        stock_qty: Number(result.stock_qty),
                        hold_qty: Number(result.hold_qty),
                        is_out_stock: result.is_out_stock,
                        track_inventory: item.children[i].product_track_inventory,
                        published: item.children[i].published,
                        weight: Number(result.vweight)
                      })
                    }
                  } else {
                    data_commodity[idx].push({
                      id: item.children[i].id,
                      variant_id: item.children[i].product_variant_id,
                      name: item.children[i].product_name,
                      variant_name: result.name,
                      image: result.photo_md,
                      disabled: true,
                      qty: Number(item.children[i].qty),
                      stock_qty: Number(result.stock_qty),
                      hold_qty: Number(result.hold_qty),
                      is_out_stock: result.is_out_stock,
                      track_inventory: item.children[i].product_track_inventory,
                      published: item.children[i].published,
                      weight: Number(result.vweight)
                    })
                  }
                }
              }
            } else {
              if (item.children[i].variant.length > 0 && item.children[i].product_variant_id === 0) {
                for (var y in item.children[i].variant) {
                  data_commodity[idx].push({
                    id: item.children[i].id,
                    variant_id: item.children[i].variant[y].id,
                    name: item.children[i].product_name,
                    variant_name: item.children[i].variant[y].name,
                    image: item.children[i].variant[y].photo_md,
                    disabled: false,
                    qty: Number(item.children[i].qty),
                    stock_qty: Number(item.children[i].variant[y].stock_qty),
                    hold_qty: Number(item.children[i].variant[y].hold_qty),
                    is_out_stock: item.children[i].variant[y].is_out_stock,
                    track_inventory: item.children[i].product_track_inventory,
                    published: item.children[i].published,
                    weight: Number(item.children[i].variant[y].vweight)
                  })
                }
              } else {
                const result = item.children[i].variant.find(obj => {
                  return obj.id === item.children[i].product_variant_id
                })
                data_commodity[idx].push({
                  id: item.children[i].id,
                  variant_id: item.children[i].product_variant_id,
                  name: item.children[i].product_name,
                  variant_name: result.name,
                  image: result.photo_md,
                  disabled: false,
                  qty: Number(item.children[i].qty),
                  stock_qty: Number(result.stock_qty),
                  hold_qty: Number(result.hold_qty),
                  is_out_stock: result.is_out_stock,
                  track_inventory: item.children[i].product_track_inventory,
                  published: item.children[i].published,
                  weight: Number(result.vweight)
                })
              }
            }
          } else {
            if (!item.children[i].product_is_out_stock) {
              if (item.children[i].product_track_inventory) {
                const stock_incart = Vue.prototype.$stockCart(item.children[i].product_id)
                if (Number(item.children[i].product_stock_qty) - Number(item.children[i].product_hold_qty) >= (Number(item.children[i].qty) + stock_incart)) {
                  data_commodity[idx].push({
                    id: item.children[i].id,
                    variant_id: '',
                    name: item.children[i].product_name, /* tuna 2 */
                    variant_name: '',
                    image: item.children[i].photo_md,
                    disabled: false,
                    qty: Number(item.children[i].qty),
                    stock_qty: Number(item.children[i].product_stock_qty),
                    hold_qty: Number(item.children[i].product_hold_qty),
                    is_out_stock: item.children[i].product_is_out_stock,
                    track_inventory: item.children[i].product_track_inventory,
                    published: item.children[i].published,
                    weight: Number(item.children[i].vweight)
                  })
                } else {
                  data_commodity[idx].push({
                    id: item.children[i].id,
                    variant_id: '',
                    name: item.children[i].product_name,
                    variant_name: '',
                    image: item.children[i].photo_md,
                    disabled: true,
                    qty: Number(item.children[i].qty),
                    stock_qty: Number(item.children[i].product_stock_qty),
                    hold_qty: Number(item.children[i].product_hold_qty),
                    is_out_stock: item.children[i].product_is_out_stock,
                    track_inventory: item.children[i].product_track_inventory,
                    published: item.children[i].published,
                    weight: Number(item.children[i].vweight)
                  })
                }
              } else {
                data_commodity[idx].push({
                  id: item.children[i].id,
                  variant_id: '',
                  name: item.children[i].product_name,
                  variant_name: '',
                  image: item.children[i].photo_md,
                  disabled: false,
                  qty: Number(item.children[i].qty),
                  stock_qty: Number(item.children[i].product_stock_qty),
                  hold_qty: Number(item.children[i].product_hold_qty),
                  is_out_stock: item.children[i].product_is_out_stock,
                  track_inventory: item.children[i].product_track_inventory,
                  published: item.children[i].published,
                  weight: Number(item.children[i].vweight)
                })
              }
            }
          }
        }
      } else if (item.children.length === 0 && item.product_has_variant) {
        data_commodity[idx] = []
        if (item.product_track_inventory) {
          if (item.variant.length > 0 && item.product_variant_id === 0) {
            for (var u in item.variant) {
              if (!item.variant[u].is_out_stock) {
                const stock_incart = Vue.prototype.$stockCart(item.variant[u].id, 'variant')
                if (Number(item.variant[u].stock_qty) - Number(item.variant[u].hold_qty) >= (Number(item.qty) + stock_incart)) {
                  data_commodity[idx].push({
                    id: item.id,
                    variant_id: item.variant[u].id,
                    name: item.product_name,
                    variant_name: item.variant[u].name,
                    image: item.variant[u].photo_md,
                    disabled: false,
                    qty: Number(item.qty),
                    stock_qty: Number(item.variant[u].stock_qty),
                    hold_qty: Number(item.variant[u].hold_qty),
                    is_out_stock: item.variant[u].is_out_stock,
                    track_inventory: item.product_track_inventory,
                    published: item.published,
                    weight: Number(item.variant[u].vweight)
                  })
                } else {
                  data_commodity[idx].push({
                    id: item.id,
                    variant_id: item.variant[u].id,
                    name: item.product_name,
                    variant_name: item.variant[u].name,
                    image: item.variant[u].photo_md,
                    disabled: true,
                    qty: Number(item.qty),
                    stock_qty: Number(item.variant[u].stock_qty),
                    hold_qty: Number(item.variant[u].hold_qty),
                    is_out_stock: item.variant[u].is_out_stock,
                    track_inventory: item.product_track_inventory,
                    published: item.published,
                    weight: Number(item.variant[u].vweight)
                  })
                }
              } else {
                data_commodity[idx].push({
                  id: item.id,
                  variant_id: item.variant[u].id,
                  name: item.product_name,
                  variant_name: item.variant[u].name,
                  image: item.variant[u].photo_md,
                  disabled: true,
                  qty: Number(item.qty),
                  stock_qty: Number(item.variant[u].stock_qty),
                  hold_qty: Number(item.variant[u].hold_qty),
                  is_out_stock: item.variant[u].is_out_stock,
                  track_inventory: item.product_track_inventory,
                  published: item.published,
                  weight: Number(item.variant[u].vweight)
                })
              }
            }
          } else {
            const result = item.variant.find(obj => {
              return obj.id === item.product_variant_id
            })
            if (typeof result !== 'undefined') {
              if (!result.is_out_stock) {
                const stock_incart = Vue.prototype.$stockCart(result.id, 'variant')
                if (Number(result.stock_qty) - Number(result.hold_qty) >= (Number(item.qty) + stock_incart)) {
                  data_commodity[idx].push({
                    id: item.id,
                    variant_id: item.product_variant_id,
                    name: item.product_name,
                    variant_name: result.name,
                    image: result.photo_md,
                    disabled: false,
                    qty: Number(item.qty),
                    stock_qty: Number(result.stock_qty),
                    hold_qty: Number(result.hold_qty),
                    is_out_stock: result.is_out_stock,
                    track_inventory: item.product_track_inventory,
                    published: item.published,
                    weight: Number(result.vweight)
                  })
                } else {
                  data_commodity[idx].push({
                    id: item.id,
                    variant_id: item.product_variant_id,
                    name: item.product_name,
                    variant_name: result.name,
                    image: result.photo_md,
                    disabled: true,
                    qty: Number(item.qty),
                    stock_qty: Number(result.stock_qty),
                    hold_qty: Number(result.hold_qty),
                    is_out_stock: result.is_out_stock,
                    track_inventory: item.product_track_inventory,
                    published: item.published,
                    weight: Number(result.vweight)
                  })
                }
              } else {
                data_commodity[idx].push({
                  id: item.id,
                  variant_id: item.product_variant_id,
                  name: item.product_name,
                  variant_name: result.name,
                  image: result.photo_md,
                  disabled: true,
                  qty: Number(item.qty),
                  stock_qty: Number(result.stock_qty),
                  hold_qty: Number(result.hold_qty),
                  is_out_stock: result.is_out_stock,
                  track_inventory: item.product_track_inventory,
                  published: item.published,
                  weight: Number(item.variant[u].vweight)
                })
              }
            }
          }
        } else {
          if (item.variant.length > 0 && item.product_variant_id === 0) {
            for (var q in item.variant) {
              data_commodity[idx].push({
                id: item.id,
                variant_id: item.variant[q].id,
                name: item.product_name,
                variant_name: item.variant[q].name,
                image: item.variant[q].photo_md,
                disabled: false,
                qty: Number(item.qty),
                stock_qty: Number(item.variant[q].stock_qty),
                hold_qty: Number(item.variant[q].hold_qty),
                is_out_stock: item.variant[q].is_out_stock,
                track_inventory: item.product_track_inventory,
                published: item.published,
                weight: Number(item.variant[q].vweight)
              })
            }
          } else {
            const result = item.variant.find(obj => {
              return obj.id === item.product_variant_id
            })
            if (result.is_out_stock) {
              data_commodity[idx].push({
                id: item.id,
                variant_id: item.product_variant_id,
                name: item.product_name,
                variant_name: result.name,
                image: result.photo_md,
                disabled: true,
                qty: Number(item.qty),
                stock_qty: Number(result.stock_qty),
                hold_qty: Number(result.hold_qty),
                is_out_stock: result.is_out_stock,
                track_inventory: item.product_track_inventory,
                published: item.published,
                weight: Number(result.vweight)
              })
            } else {
              data_commodity[idx].push({
                id: item.id,
                variant_id: item.product_variant_id,
                name: item.product_name,
                variant_name: result.name,
                image: result.photo_md,
                disabled: false,
                qty: Number(item.qty),
                stock_qty: Number(result.stock_qty),
                hold_qty: Number(result.hold_qty),
                is_out_stock: result.is_out_stock,
                track_inventory: item.product_track_inventory,
                published: item.published,
                weight: Number(result.vweight)
              })
            }
          }
        }
      } else {
        if (item.product_track_inventory) {
          if (!item.product_is_out_stock) {
            const stock_incart = Vue.prototype.$stockCart(item.id, 'product')
            if (Number(item.stock_qty) - Number(item.hold_qty) >= (Number(item.qty) + stock_incart)) {
              data_commodity[idx].push({
                id: item.id,
                variant_id: '',
                name: item.product_name,
                variant_name: '',
                image: item.photo_md,
                disabled: false,
                qty: Number(item.qty),
                stock_qty: Number(item.stock_qty),
                hold_qty: Number(item.hold_qty),
                is_out_stock: item.product_is_out_stock,
                track_inventory: item.product_track_inventory,
                published: item.published,
                weight: Number(item.weight)
              })
            } else {
              data_commodity[idx].push({
                id: item.id,
                variant_id: '',
                name: item.product_name,
                variant_name: '',
                image: item.photo_md,
                disabled: true,
                qty: Number(item.qty),
                stock_qty: Number(item.stock_qty),
                hold_qty: Number(item.hold_qty),
                is_out_stock: item.product_is_out_stock,
                track_inventory: item.product_track_inventory,
                published: item.published,
                weight: Number(item.weight)
              })
            }
          } else {
            data_commodity[idx].push({
              id: item.id,
              variant_id: '',
              name: item.product_name,
              variant_name: '',
              image: item.photo_md,
              disabled: true,
              qty: Number(item.qty),
              stock_qty: Number(item.stock_qty),
              hold_qty: Number(item.hold_qty),
              is_out_stock: item.product_is_out_stock,
              track_inventory: item.product_track_inventory,
              published: item.published,
              weight: Number(item.weight)
            })
          }
        } else {
          data_commodity[idx].push({
            id: item.id,
            variant_id: '',
            name: item.product_name,
            variant_name: '',
            image: item.photo_md,
            disabled: false,
            qty: Number(item.qty),
            stock_qty: Number(item.stock_qty),
            hold_qty: Number(item.hold_qty),
            is_out_stock: item.is_out_stock,
            track_inventory: item.product_track_inventory,
            published: item.published,
            weight: Number(item.weight)
          })
        }
      }
    })
  }
  return data_commodity
}

/**
 * nilai return berupa { weight, fvweight }
 * @param {*} detail_product
 * @param {*} data_form
 * @returns
 */
Vue.prototype.$getProductWeight = function(detail_product, data_form = null, type = 'product') {
  if (!detail_product) {
    return 0
  }
  var result = {}
  if (type === 'product') {
    result = {
      combo_id: null,
      product_id: detail_product.id,
      variant_id: null,
      weight: Number(detail_product.weight),
      fweight: detail_product.fweight
    }
    if (data_form.product_variant_id) {
      const idx = detail_product.variants.findIndex((e) => e.id === data_form.product_variant_id)
      if (idx !== -1) {
        result = {
          combo_id: null,
          product_id: detail_product.id,
          variant_id: detail_product.variants[idx].id,
          weight: Number(detail_product.variants[idx].vweight),
          fweight: detail_product.variants[idx].fvweight
        }
      }
    } else {
      if (detail_product.variants && detail_product.variants.length > 0) {
        const idx = detail_product.variants.findIndex((e) => e.is_default === 1)
        if (idx !== -1) {
          result = {
            combo_id: null,
            product_id: detail_product.id,
            variant_id: detail_product.variants[idx].id,
            weight: Number(detail_product.variants[idx].vweight),
            fweight: detail_product.variants[idx].fvweight
          }
        } else {
          result = {
            combo_id: null,
            product_id: detail_product.id,
            variant_id: detail_product.variants[0].id,
            weight: Number(detail_product.variants[0].vweight),
            fweight: detail_product.variants[0].fvweight
          }
        }
      }
    }
  } else {
    if (data_form?.product_combo_items?.length > 0) {
      let weight = 0
      data_form.product_combo_items.forEach(item => {
        weight += item.weight
      })
      result = {
        combo_id: detail_product.id,
        product_id: null,
        variant_id: null,
        weight: weight,
        fweight: `${weight} Kg`
      }
    } else {
      result = {
        combo_id: detail_product.id,
        product_id: null,
        variant_id: null,
        weight: 0,
        fweight: '0 Kg'
      }
    }
  }
  return result
}

Vue.prototype.$isEqual = function(obj1, obj2) {
  const stack = [{ obj1, obj2 }]

  while (stack.length > 0) {
    const { obj1, obj2 } = stack.pop()

    if (obj1 === obj2) {
      continue
    }

    if (obj1 === null || obj2 === null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      if (obj1 !== obj2) {
        return false
      }
      continue
    }

    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)

    if (keys1.length !== keys2.length) {
      return false
    }

    for (const key of keys1) {
      if (!keys2.includes(key)) {
        return false
      }

      stack.push({ obj1: obj1[key], obj2: obj2[key] })
    }
  }

  return true
}

Vue.prototype.$filterInputNumber = function(e) {
  var k = e.which
  if ((k < 48 || k > 57) && (k < 96 || k > 105) && k !== 8) {
    e.preventDefault()
    return false
  }
}

Vue.prototype.$stockCartCombo = function(dataComboId) {
  let qty = 0
  if (!dataComboId) {
    return qty
  }
  const LOCAL_DATA_CART = localStorage.getItem(process.env.VUE_APP_COOKIE_KEY + '_data_cart')
  if (LOCAL_DATA_CART) {
    const cart = JSON.parse(LOCAL_DATA_CART)
    if (cart.item.length > 0) {
      cart.item.forEach((cartItem) => {
        if (cartItem.product_combo_id && Number(cartItem.product_combo_id) === Number(dataComboId)) {
          qty += cartItem.qty
        }
      })
    }
  }
  return qty
}

Vue.prototype.$getStockInCart = function(detail_product, form) {
  let stock_in_cart = 0
  if (detail_product.has_variant) {
    const elementId = form.product_variant_id
    const stock_incart = Vue.prototype.$stockCart(elementId, 'variant')
    stock_in_cart = stock_in_cart + stock_incart
  } else {
    const stock_incart = Vue.prototype.$stockCart(detail_product.id)
    stock_in_cart = stock_in_cart + stock_incart
  }
  return stock_in_cart
}

Vue.prototype.$getStockComboInCart = function(detail_product) {
  let stock_in_cart = 0
  const elementId = detail_product.id
  const stock_incart = Vue.prototype.$stockCartCombo(elementId)
  stock_in_cart = stock_in_cart + stock_incart
  return stock_in_cart
}

Vue.prototype.$decimalToPercentage = function(decimal, isUsingFormat = false) {
  let decimalValue = decimal
  if (typeof decimal === 'string') {
    decimalValue = Number(decimal)
  }

  if (decimalValue < 0 || decimalValue > 1) {
    throw new Error('Nilai desimal harus berada dalam rentang 0 hingga 1.')
  }

  // Ubah desimal menjadi persentase
  const percentageValue = decimalValue * 100
  return isUsingFormat ? `${percentageValue}%` : percentageValue
}

Vue.prototype.$goToWhatsApp = function(phone_number, type = 'url') {
  const url = `https://wa.me/${phone_number}`

  if (type === 'url') {
    return url
  }
  if (type === 'window') {
    window.open(url, '_blank')
    return
  }
  return url
}

/**
 * Menghitung status waktu operasional berdasarkan waktu akhir dan batasan waktu penutupan
 * @param {string} startTime - Waktu operasional dimulai dalam format 24 jam, misalnya "08:00:00"
 * @param {string} endTime - Waktu operasional berakhir dalam format 24 jam, misalnya "17:00:00"
 * @param {number} closingTimeThreshold - Batasan waktu penutupan dalam menit, defaultnya 45
 * @returns {Object} - { type: 'close', closingTime: 0 }
 */
Vue.prototype.$closingTime = function(params) {
  params = {
    startTime: null,
    endTime: null,
    closingTimeThreshold: 45,
    ...params
  }
  const result = {
    type: 'close',
    closingTime: 0
  }

  if (!params.endTime) return result

  let startHour = 0
  let startMinutes = 0
  if (!params.startTime) {
    startHour = new Date().getHours()
    startMinutes = new Date().getMinutes()
  } else {
    startHour = parseInt(params.startTime.split(':')[0], 10)
    startMinutes = parseInt(params.startTime.split(':')[1], 10)
  }

  const endHour = parseInt(params.endTime.split(':')[0], 10)
  const endMinutes = parseInt(params.endTime.split(':')[1], 10)

  const totalCurrentMinutes = (startHour * 60) + startMinutes
  const totalEndMinutes = (endHour * 60) + endMinutes

  const timeDifferenceInMinutes = totalEndMinutes - totalCurrentMinutes

  if (timeDifferenceInMinutes <= 0) {
    result.type = 'close'
    result.closingTime = 0
    return result
  } else if (timeDifferenceInMinutes < params.closingTimeThreshold) {
    result.type = 'closing'
    result.closingTime = timeDifferenceInMinutes
    return result
  } else {
    result.type = 'open'
    result.closingTime = 0
    return result
  }
}

/**
 * Untuk menampilkan product masih tersedia atau tidak, hanya untuk halaman product variant dan product biasa
 * @param {*} detailProduct
 * @param {*} selectedProduct
 * @returns
 */
Vue.prototype.$textInfoProductStock = function(detailProduct, selectedProduct) {
  const detail_product = detailProduct
  const selected_product = selectedProduct
  let stock = 0
  let text = this.$t('product.available')

  if (!detail_product) {
    return this.$t('product.not_available')
  }

  if (detail_product.has_variant) {
    if (selected_product.product_variant_id) {
      const idx = detail_product.variants.findIndex((e) => e.id === selected_product.product_variant_id)
      if (idx !== -1) {
        if (detail_product.variants[idx].is_out_stock) {
          text = this.$t('product.not_available')
        } else {
          if (detail_product.track_inventory) {
            stock = Number(detail_product.variants[idx].stock_qty) - Number(detail_product.variants[idx].hold_qty)
            if (stock <= 0) {
              text = this.$t('product.not_available')
            } else if (stock <= 10) {
              text = this.$t('product.stock_left_min', { stock: stock })
            } else {
              text = this.$t('product.stock_left', { stock: stock })
            }
          }
        }
      }
    }
  } else {
    if (detail_product.is_out_stock) {
      text = this.$t('product.not_available')
    } else {
      if (detail_product.track_inventory) {
        stock = Number(detail_product.stock_qty) - Number(detail_product.hold_qty)
        if (stock <= 0) {
          text = this.$t('product.not_available')
        } else if (stock <= 10) {
          text = this.$t('product.stock_left_min', { stock: stock })
        } else {
          text = this.$t('product.stock_left', { stock: stock })
        }
      }
    }
  }
  return text
}

/**
 * For detail product & combo
 */
Vue.prototype.$validationProduct = function(type = 'product', detailProductItem, form) {
  const detail_product = detailProductItem
  const data = {
    valid: true,
    message: '',
    hold_qty: Number(detail_product.hold_qty),
    is_out_stock: detail_product.is_out_stock,
    stock_qty: Number(detail_product.stock_qty),
    image: detail_product.photo_lg,
    status_type: ''
    // remaining_stock: Number(detail_product.stock_qty)
  }

  const minOrder = Number(detail_product.min_order)
  const maxOrder = Number(detail_product.max_order)
  let stock_in_cart = form.qty
  if (detail_product.has_variant) {
    if (!form.product_variant_id) {
      data.valid = false
      data.message = this.$t('product.select_variant_first')
      data.status_type = 'error_select_variant_first'
      return data
    }
  }
  if (stock_in_cart <= 0) {
    data.valid = false
    data.message = this.$t('product.invalid_purchase_qty_min')
    data.status_type = 'error_qty_min'
    return data
  }
  if (minOrder > 0 && maxOrder > 0) {
    // Jika minOrder dan maxOrder lebih dari 0, cek stock_in_cart dengan keduanya
    if (type === 'product') {
      stock_in_cart += Vue.prototype.$getStockInCart(detail_product, form)
    } else {
      stock_in_cart += Vue.prototype.$getStockComboInCart(detail_product)
    }
    if (stock_in_cart < minOrder || stock_in_cart > maxOrder) {
      data.valid = false
      if (stock_in_cart < minOrder) {
        data.message = this.$t('product.invalid_purchase_qty_min')
        data.status_type = 'error_qty_min'
      } else {
        data.message = this.$t('product.invalid_purchase_qty_max')
        data.status_type = 'error_qty_max'
      }
      return data
    }
  } else if (minOrder > 0 && maxOrder < 1) {
    // Jika minOrder lebih dari 0 dan maxOrder lebih kecil dari 1, cek stock_in_cart dengan minOrder saja
    if (type === 'product') {
      stock_in_cart += Vue.prototype.$getStockInCart(detail_product, form)
    } else {
      stock_in_cart += Vue.prototype.$getStockComboInCart(detail_product)
    }
    if (stock_in_cart < minOrder) {
      data.valid = false
      data.message = this.$t('product.invalid_purchase_qty_min')
      data.status_type = 'error_qty_min'
      return data
    }
  } else if (maxOrder > 0 && minOrder < 1) {
    // Jika maxOrder lebih dari 0 dan minOrder lebih kecil dari 1, cek stock_in_cart dengan maxOrder saja
    if (type === 'product') {
      stock_in_cart += Vue.prototype.$getStockInCart(detail_product, form)
    } else {
      stock_in_cart += Vue.prototype.$getStockComboInCart(detail_product)
    }
    if (stock_in_cart > maxOrder) {
      data.valid = false
      data.message = this.$t('product.invalid_purchase_qty_max')
      data.status_type = 'error_qty_max'
      return data
    }
  }

  if (type === 'product') {
    if (detail_product.track_inventory) {
      if (detail_product.has_variant) {
        if (!form.product_variant_id) {
          data.valid = false
          data.message = this.$t('product.select_variant_first')
          data.status_type = 'error_select_variant_first'
        } else {
          const stock_incart = Vue.prototype.$stockCart(form.product_variant_id, 'variant')
          const idx = detail_product.variants.findIndex((e) => e.id === form.product_variant_id)
          data.valid = Number(detail_product.variants[idx].stock_qty) - Number(detail_product.variants[idx].hold_qty) >= (Number(form.qty) + stock_incart) && !detail_product.variants[idx].is_out_stock
          data.message = this.$t('product.stock_is_not_enough', { data: detail_product.variants[idx].name })
          data.hold_qty = Number(detail_product.variants[idx].hold_qty)
          data.is_out_stock = detail_product.variants[idx].is_out_stock
          data.stock_qty = Number(detail_product.variants[idx].stock_qty)
          data.image = detail_product.variants[idx].photo_lg
          data.status_type = 'error_stock_is_not_enough'
          // data.remaining_stock = !detail_product.variants[idx].is_out_stock ? (Number(detail_product.variants[idx].stock_qty) - Number(detail_product.variants[idx].hold_qty)) - stock_incart : 0
        }
      } else {
        const stock_incart = Vue.prototype.$stockCart(detail_product.id)
        data.valid = Number(detail_product.stock_qty) - Number(detail_product.hold_qty) >= (Number(form.qty) + stock_incart) && !detail_product.is_out_stock
        data.message = this.$t('product.stock_is_not_enough', { data: detail_product.name })
        data.status_type = 'error_stock_is_not_enough'
        // data.remaining_stock = !detail_product.is_out_stock ? (Number(detail_product.stock_qty) - Number(detail_product.hold_qty)) - stock_incart : 0
      }
    } else {
      if (detail_product.has_variant) {
        if (!form.product_variant_id) {
          data.valid = false
          data.message = this.$t('product.select_variant_first')
          data.status_type = 'error_select_variant_first'
        } else {
          const idx = detail_product.variants.findIndex((e) => e.id === form.product_variant_id)
          data.valid = !detail_product.variants[idx].is_out_stock
          data.message = this.$t('product.stock_is_not_enough', { data: detail_product.variants[idx].name })
          data.hold_qty = Number(detail_product.variants[idx].hold_qty)
          data.is_out_stock = detail_product.variants[idx].is_out_stock
          data.stock_qty = Number(detail_product.variants[idx].stock_qty)
          data.image = detail_product.variants[idx].photo_lg
          data.status_type = 'error_stock_is_not_enough'
        }
      } else {
        data.valid = !detail_product.is_out_stock
        data.message = this.$t('product.stock_is_not_enough', { data: detail_product.name })
        data.status_type = 'error_stock_is_not_enough'
      }
    }
  } else {
    if (form.product_combo_items.length > 0 && detail_product.items.length === form.product_combo_items.length) {
      for (let i = 0; i < form.product_combo_items.length; i++) {
        if (typeof form.product_combo_items[i] === 'undefined') {
          data.valid = false
          data.message = this.$t('v2.complete_the_required_fields')
          data.status_type = 'error_complete_the_required_fields'
          break
        } else {
          data.hold_qty = 0
          data.is_out_stock = detail_product.is_out_stock
          data.stock_qty = Number(detail_product.stock_qty)
          if (form.product_combo_items[i].published) {
            if (form.product_combo_items[i].track_inventory) {
              if (form.product_combo_items[i].variant_id !== '') {
                const stock_incart = Vue.prototype.$stockCart(form.product_combo_items[i].variant_id, 'variant')
                data.valid = Number(form.product_combo_items[i].stock_qty) - Number(form.product_combo_items[i].hold_qty) >= ((Number(form.qty) * form.product_combo_items[i].qty) + stock_incart) && !form.product_combo_items[i].is_out_stock
                data.message = this.$t('product.stock_is_not_enough', { data: form.product_combo_items[i].name })
                data.status_type = 'error_stock_is_not_enough'
                if (data.valid === false) {
                  break
                }
              } else {
                const stock_incart = Vue.prototype.$stockCart(form.product_combo_items[i].id)
                data.valid = Number(form.product_combo_items[i].stock_qty) - Number(form.product_combo_items[i].hold_qty) >= ((Number(form.qty) * form.product_combo_items[i].qty) + stock_incart) && !form.product_combo_items[i].is_out_stock
                data.message = this.$t('product.stock_is_not_enough', { data: form.product_combo_items[i].name })
                data.status_type = 'error_stock_is_not_enough'
                if (data.valid === false) {
                  break
                }
              }
            } else {
              data.valid = !form.product_combo_items[i].is_out_stock
              data.message = this.$t('product.stock_is_not_enough', { data: form.product_combo_items[i].name })
              data.status_type = 'error_stock_is_not_enough'
              if (data.valid === false) {
                break
              }
            }
          } else {
            data.valid = false
            data.message = this.$t('v2.products_have_not_been_published', { item: form.product_combo_items[i].name })
            data.status_type = 'error_product_not_published'
            break
          }
        }
      }
    } else {
      data.valid = false
      data.message = this.$t('v2.complete_the_required_fields')
      data.status_type = 'error_complete_required_fields'
    }
  }

  return data
}
