<template>
  <v-dialog v-model="isOpen" persistent width="344px">
    <v-card>
      <div class="alert-store-off__header">
        <div class="alert-store-off__header-title">
          <span class="">{{ selectedOutlet.name }}</span>
        </div>
        <v-btn icon class="alert-store-off__header-close" @click="onClose">
          <v-icon color="#272727">
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <div class="alert-store-off__banner">
        <v-img width="100%" height="150px" :src="require('@/assets/img/svg/banner_store_closed.svg')" />
      </div>
      <div class="alert-store-off__description">
        <span class="dialog-list-outlet_store-off__alert-title">{{ $t('dialog.alert_store_off_title') }}</span>
        <span class="dialog-list-outlet_store-off__alert-caption mt-2">{{ $t('dialog.alert_store_off_description') }}</span>
        <span class="dialog-list-outlet_store-off__alert-time mt-2">
          {{ _reformatDate }}
        </span>
      </div>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" depressed block large class="text-none font-16 mb-3" @click="onSubmit">
          {{ $t('dialog.continue_buying') }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { getNextWorkTime } from '@/utils/storeOperational'
import { formatMonthToString } from '@/utils/helpers'

export default {
  name: 'DialogAlertStoreOff',
  props: {
    isOpen: {
      default: false,
      type: Boolean
    },
    onClose: {
      default: () => console.log('close'),
      type: Function
    },
    onSubmit: {
      default: () => console.log('submit'),
      type: Function
    }
  },
  computed: {
    selectedOutlet() {
      return this.$store.getters.selectedOutlet
    },
    _reformatDate() {
      const date = new Date(this.getWorkingHour().date)
      const getDate = date.getDate()
      const getMonth = formatMonthToString(date.getMonth())
      const getFullYear = date.getFullYear()
      return `${getDate} ${getMonth} ${getFullYear}, ${this.getWorkingHour().times[0].fstart_time}`
    }
  },
  methods: {
    getWorkingHour() {
      return getNextWorkTime(this.selectedOutlet.store_open_date_tomorrow, this.selectedOutlet.store_closing_date_today)
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-store-off {
 &__header {
  display: flex;
  padding: 14.5px 16px;
  align-items: center;
  &-title {
    flex: 1;
    font-weight: 700;
    font-size: 20px;
  }
 }
 &__description{
  margin-top: 24px;
  padding:0 16px;
  padding-bottom: 16px;
 }
}
</style>
