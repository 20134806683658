<template lang="html">
  <div>
    <div class="border-radius-8 pa-4 border-soft-grey _list">
      <div class="_list-group">
        <template v-for="(item, idx) in arrItem">
          <div
            :key="idx + '_items'"
            :class="['_list-item _list-item-product-combo pa-4 border-radius-8 cursor-pointer', {
              'disabled': isStockUnavailable
            }]"
            @click="!isStockUnavailable ? showDialogItem(idx, item) : ''"
          >
            <div class="_list-item-image mr-2">
              <img :src="item.image" :alt="item.name" class="image-40 border-radius-5">
            </div>
            <div class="_list-item-content">
              <div class="_list-item-text font-14 font-weight-600">
                {{ item.name }}
              </div>
              <div class="_list-item-text font-12 color-grey">
                {{ item.variant_name }}
              </div>
              <div class="_list-item-text font-12 color-grey">
                {{ item.qty }} pcs
              </div>
            </div>
            <div class="_list-item-action">
              <img src="@/assets/img/png/chevron-down.png" alt="Olsera.com" class="image-24">
            </div>
          </div>
        </template>
      </div>
    </div>
    <v-dialog v-model="dialog.item_combo" persistent content-class="border-radius-10" max-width="500">
      <v-card class="p-relative">
        <div class="d-flex align-center justify-center pa-4">
          <div class="font-18 font-weight-bold">
            {{ $t('product.product_combo_options') }}
          </div>
          <v-btn icon class="btn-close-dialog" @click="dialog.item_combo = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <div class="pa-4">
          <div class="_list-group">
            <template v-for="(item, idx) in item_commodity">
              <div :key="idx + '_items'" :class="['_list-item _list-item-product-combo pa-4 border-radius-8 cursor-pointer', { 'selected': idx === selected_index }]" @click="selectItem(item)">
                <div class="_list-item-image mr-2">
                  <img :src="item.image" :alt="item.name" class="image-40 border-radius-5">
                </div>
                <div class="_list-item-content">
                  <div class="_list-item-text font-14 font-weight-600">
                    {{ item.name }}
                  </div>
                  <div class="_list-item-text font-12 color-grey">
                    {{ item.variant_name }}
                  </div>
                  <div class="_list-item-text font-12 color-grey">
                    {{ item.qty }} pcs
                  </div>
                </div>
                <div v-if="idx === selected_index" class="_list-item-action">
                  <img src="@/assets/img/png/check.png" alt="Olsera.com" class="image-24">
                </div>
              </div>
            </template>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    isStockUnavailable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: {
        item_combo: false
      },
      item_commodity: [],
      selected_index: 0,
      temp_index: 0
    }
  },
  computed: {
    'arrItem': {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    showDialogItem(idx, item) {
      this.dialog.item_combo = true
      this.item_commodity = this.items[idx]
      this.temp_index = idx
      for (let i = 0; i < this.item_commodity.length; i++) {
        if (item.variant_id && this.item_commodity[i].variant_id === item.variant_id) {
          this.selected_index = i
          break
        } else if (item.variant_id === '' && this.item_commodity[i].id === item.id) {
          this.selected_index = i
          break
        }
      }
    },
    selectItem(item) {
      this.arrItem[this.temp_index] = item
      this.dialog.item_combo = false
    }
  }
}
</script>
