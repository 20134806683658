div<template>
  <div>
    <BannerStore :logo="logoStore" />
    <div class="branch-store_section-title mt-4 container col-lg-6 col-md-8 col-sm-10 col-12">
      <span class="branch-store_section-title__title">{{ $t('merchant.select_branch') }}</span>
      <span class="branch-store_section-title__total">{{ totalBranch }} {{ $t('merchant.branch') }}</span>
    </div>
    <div class="branch-store_section-search mt-4 container col-lg-6 col-md-8 col-sm-10 col-12">
      <v-text-field
        v-model="search"
        hide-details
        prepend-inner-icon="mdi-magnify"
        solo
        class="shadow-input"
        :placeholder="$t('component.search')"
        clearable
      />
    </div>
    <div v-if="searchNotFound && isModeSearch" class="my-4 container col-lg-6 col-md-8 col-sm-10 col-12">
      <div class="branch-store_section-search-not-found">
        <v-img class="branch-store_section-search-not-found__img" :src="require('@/assets/img/svg/icon_no_data.svg')" />
        <span class="branch-store_section-search-not-found__text">{{ $t('merchant.branch_not_found') }}</span>
      </div>
    </div>
    <div v-if="outlets.length > 0" class="my-4 container px-0 py-0 col-lg-6 col-md-8 col-sm-10 col-12">
      <ItemBranchStore
        v-for="(outlet, index) in outlets"
        :key="outlet.id"
        v-intersect="index === lastOutletIndex ? onIntersect : undefined"
        :logo="outlet.logo"
        :store-name="outlet.name"
        :store-time="outlet.fstore_status === fstoreStatus()._OFF ? '00:00 - 00:00' : handleJoinMultipleTime(outlet.shop_hours_today)"
        :store-address="outlet.address"
        :store-distance="outlet.location_distance.text"
        :store-status="outlet.fstore_status"
        :on-click="() => handleClickOutlet(outlet)"
      />
      <div v-if="loadingInfiniteScroll" class="d-flex align-center justify-center px-4 py-4">
        <v-progress-circular indeterminate color="blue" size="24" />
      </div>
    </div>
  </div>
</template>

<script>
import BannerStore from '@/components/BannerStore.vue'
import ItemBranchStore from '@/components/ItemBranchStore.vue'
import { listOutlet } from '@/api/outlet'
import debounce from 'debounce'
import { FSTORE_STATUS, getNextWorkTime } from '@/utils/storeOperational'

export default {
  name: 'BranchStore',
  components: {
    BannerStore,
    ItemBranchStore
  },
  data() {
    return {
      search: '',
      outlets: [],
      outletsMeta: null,
      searchNotFound: false,
      isModeSearch: false,
      page: 1,
      per_page: 15,
      loadingInfiniteScroll: false
    }
  },
  computed: {
    loading() {
      return this.$store.state.global.loading
    },
    mainStore() {
      return this.$store.getters.mainStore
    },
    logoStore() {
      return this.mainStore.logo
    },
    totalBranch() {
      if (this.outletsMeta) {
        return this.outletsMeta.total
      } else {
        return 0
      }
    },
    lastOutletIndex() {
      return this.outlets.length - 1
    }
  },
  watch: {
    search: debounce(function() {
      this.isModeSearch = true
      this.outlets = []
      this.outletsMeta = null
      this.page = 1
      this.fetchListOutlet()
    }, 500)
  },
  mounted() {
    this.fetchListOutlet()
  },
  methods: {
    onIntersect(entries, observer) {
      if (entries[0].isIntersecting) {
        if (!this.loading && this.outletsMeta.last_page !== this.page) {
          this.loadingInfiniteScroll = true
          this.page += 1
          this.fetchListOutlet()
        }
      }
    },
    async getCoords() {
      const pos = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject)
      })

      return {
        long: pos.coords.longitude,
        lat: pos.coords.latitude
      }
    },
    async fetchListOutlet() {
      try {
        if (!this.search) {
          this.isModeSearch = false
          this.searchNotFound = false
        }
        const coords = await this.getCoords()
        const params = {
          origin_lat: coords.lat,
          origin_lon: coords.long,
          page: this.page,
          per_page: this.per_page,
          ...(this.search ? {
            search_column: 'name',
            search_operator: 'like',
            search_text: this.search
          } : {})
        }
        const res = await listOutlet(params)
        if (this.outlets.length > 0 && this.outlets.length < res.data.meta.total) {
          this.outlets = [...this.outlets, ...res.data.data]
        } else {
          this.outlets = res.data.data
        }
        this.outletsMeta = res.data.meta
      } catch (error) {
        if (this.isModeSearch) {
          this.searchNotFound = true
        }
        this.$store.dispatch('showAlert', {
          type: 'error',
          show: true,
          message: error.message
        })
      } finally {
        this.loadingInfiniteScroll = false
      }
    },
    handleClickOutlet(outlet) {
      const dataBranch = { url_id: outlet.url_id, id: outlet.id }
      this.$store.commit('SET_OUTLET', outlet)
      this.$store.dispatch('remove_token')
      this.$store.commit('SET_SELECTED_BRANCH', dataBranch)
      localStorage.removeItem(`${process.env.VUE_APP_COOKIE_KEY}_data_cart`)
      localStorage.removeItem(`${process.env.VUE_APP_COOKIE_KEY}_order`)
      this.$store.commit('REMOVE_TEMPORARY_SHIPPING')
      window.location.reload()
    },
    getWorkingHour(store_open_date_tomorrow) {
      return getNextWorkTime(store_open_date_tomorrow)
    },
    fstoreStatus() {
      return FSTORE_STATUS
    },
    handleJoinMultipleTime(shop_hours_today) {
      if (shop_hours_today.length > 0 || shop_hours_today) {
        return shop_hours_today.map(time => `${time.fstart_time} - ${time.fend_time}`).join(', ')
      }
      return '00:00 - 00:00'
    }
  }
}
</script>

<style scoped lang="scss">
.branch-store {
  &_section-title {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    align-items: center;
    &__title {
      font-size: 18px;
      font-weight: 700;
    }
    &__total {
      text-align: right;
      font-size: 14px;
      font-weight: 600;
    }
  }
  &_section-search {
    padding: 0 16px;
  }
  &_section-search-not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
    background-color: #f5f5f5;
    flex-direction: column;
    gap: 8px;
    &__img {
      width: 90px;
    }
    &__text {
      font-size: 18px;
      font-weight: 700;
    }
  }
}
</style>
