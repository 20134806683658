export const FSTORE_STATUS = {
  _OPENING: 'opening', // TOKO BENTAR LAGI BUKA
  _OFF: 'off', // TOKO LIBUR
  _CLOSE: 'close', // TOKO TUTUP
  _OPEN: 'open', // TOKO BUKA
  _CLOSING: 'closing' // TOKO SEBENTAR LAGI TUTUP
}
/**
 * param status menggunakan property fstore_status
 * status: 'opening' or 'segera buka', 'off' or 'libur', 'close' or 'tutup', 'open' or 'buka', 'closing' or 'segera tutup'
 * @param {'opening'|'off'|'close'|'open'|'closing'} status
 * @returns
 */
export const handleStoreClose = (status) => {
  if (status === FSTORE_STATUS._OPENING || status === FSTORE_STATUS._OFF || status === FSTORE_STATUS._CLOSE) {
    return true
  }
  return false
}

/**
 *
 * @param {[]} storeOpenDateTomorrow
 * @param {[]} storeClosingDateToday
 * @returns
 * example: store_open_date_tomorrow: {
    "date": "2023-07-10",
    "fdate": "10-Jul-2023",
    "times": [
      {
        "start_time": "07:00:00",
        "fstart_time": "07:00",
        "end_time": "15:00:00",
        "fend_time": "15:00"
      }
    ]
  }
 */
export function getNextWorkTime(storeOpenDateTomorrow, storeClosingDateToday) {
  let workingHour = {
    date: '',
    fdate: '',
    times: [
      {
        start_time: '00:00:00',
        fstart_time: '00:00',
        end_time: '00:00:00',
        fend_time: '00:00'
      }
    ]
  }
  const now = new Date()

  if (!storeOpenDateTomorrow || storeOpenDateTomorrow.length < 1 || !storeClosingDateToday) {
    return workingHour
  }

  // Mencari jadwal yang berlangsung sekarang
  const getWorkingHour = []
  storeOpenDateTomorrow.forEach((value) => {
    // Filter apakah dia sedang libur, jika libur maka hanya data setelah libur saja yang akan diambil
    if (storeClosingDateToday.length > 0) {
    // const storeClosingStartDate = new Date(storeClosingDateToday[0].start_date)
      const storeClosingEndDate = new Date(storeClosingDateToday[0].end_date)
      if (storeClosingEndDate < new Date(value.date)) {
        getWorkingHour.push(value)
      }
    } else {
      getWorkingHour.push(value)
    }
  })

  getWorkingHour.forEach(value => {
    if (value.times.length > 0) {
      value.times.forEach((time) => {
        const startTime = new Date(`${value.date}T${time.start_time}`)
        const endTime = new Date(`${value.date}T${time.end_time}`)

        if (
          startTime !== null &&
          endTime !== null &&
          time.start_time !== '00:00:00' &&
          time.end_time !== '00:00:00' &&
          now >= startTime &&
          now <= endTime
        ) {
          workingHour = { ...value, times: [time] }
        }
      })
    }
  })

  // Jika tidak ada jadwal berlangsung, cari jadwal berikutnya yang akan dimulai di masa depan
  if (!workingHour.date) {
    let nextStartTime = Infinity // Waktu tercepat yang akan datang
    getWorkingHour.forEach((value) => {
      if (value.times.length > 0) {
        value.times.forEach((time) => {
          const startTime = new Date(`${value.date}T${time.start_time}`)
          const endTime = new Date(`${value.date}T${time.end_time}`)

          if (
            startTime !== null &&
            endTime !== null &&
            time.start_time !== '00:00:00' &&
            time.end_time !== '00:00:00' &&
            now < startTime &&
            startTime < nextStartTime // Perbandingan untuk mencari waktu tercepat berikutnya
          ) {
            workingHour = { ...value, times: [time] }
            nextStartTime = startTime // Perbarui nilai waktu tercepat
          }
        })
      }
    })
  }

  return workingHour
}
