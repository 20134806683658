<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" offset-sm="3" md="4" offset-md="4">
        <div class="text-center" style="padding-bottom: 150px;">
          <div class="mt-12 px-2">
            <div class="img-order">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="219.845" height="166.367" viewBox="0 0 219.845 166.367" class="order_payment_pending--img">
                <defs>
                  <filter id="a" x="66.78" y="89.387" width="35.742" height="35.742" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="b" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="b" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter id="c" x="53.859" y="60.727" width="37.823" height="37.822" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="d" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="d" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter id="e" x="125.983" y="38.037" width="27.887" height="27.888" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="f" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="f" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter id="g" x="64.825" y="32.133" width="39.693" height="39.694" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="h" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="h" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter id="i" x="136.78" y="64.668" width="29.936" height="29.935" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="j" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="j" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter id="k" x="123.91" y="91.363" width="31.903" height="31.903" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="l" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="l" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter id="m" x="95.397" y="102.225" width="33.806" height="33.807" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="n" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="n" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter id="o" x="91.58" y="19.295" width="41.564" height="41.566" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="p" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="p" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                </defs>
                <g transform="translate(0.009 9.845)">
                  <path class="a" d="M61.83,433.31c3.481,13.452,10.843,25.08,22.477,30.717,24.187,11.715,78.734,5.673,119.444-1.262,21.059-3.587,39.568-13.486,53.014-27.417Z" transform="translate(-59.465 -355.352)" />
                  <path class="a" d="M297.759,58.928H275.422a1.755,1.755,0,1,1,0-3.493h3.863a1.755,1.755,0,1,1,0-3.493h-2.757a1.755,1.755,0,1,1,0-3.493h11.659a33.409,33.409,0,0,0-9.1-2.268c-31.791-3.693-43.141-1.25-47.145,1.26a47,47,0,0,1-16.34,6.48c-19.614,3.661-56.295,8.066-89.541-1.4C109.542,47.8,97.26,55.762,89.9,69.063H301.639a28.743,28.743,0,0,0-2.623-10.56,2.038,2.038,0,0,1-1.256.425Z" transform="translate(-81.91 -44.29)" />
                  <path class="a" d="M50.462,293.19a80.662,80.662,0,0,0,.866,23.469l196.114,6.491a83.2,83.2,0,0,0,13.626-18.8Zm77.566,16.742h-3.861a1.755,1.755,0,1,1,0,3.493H101.829a1.755,1.755,0,1,1,0-3.493h3.863a1.755,1.755,0,1,1,0-3.493h-2.759a1.755,1.755,0,1,1,0-3.493H125.27a1.755,1.755,0,1,1,0,3.493h2.757a1.755,1.755,0,1,1,0,3.493Z" transform="translate(-49.99 -243.31)" />
                  <path class="a" d="M271.757,168.5,57.648,173.209a71.037,71.037,0,0,0-5.128,20H268.006a76,76,0,0,0,3.11-12.751A54.584,54.584,0,0,0,271.757,168.5ZM95.992,183.94H92.13a1.756,1.756,0,1,1,0,3.495H69.793a1.756,1.756,0,1,1,0-3.495h3.863a1.755,1.755,0,1,1,0-3.493H70.9a1.755,1.755,0,1,1,0-3.493H93.235a1.755,1.755,0,1,1,0,3.493h2.757a1.755,1.755,0,1,1,0,3.493Z" transform="translate(-52.02 -143.607)" />
                  <ellipse class="a" cx="89.569" cy="8.24" rx="89.569" ry="8.24" transform="translate(19.651 140.042)" />
                  <g transform="translate(-1 21)">
                    <rect class="b" width="149.327" height="149.328" rx="74.664" transform="translate(36.652 -30.845)" />
                    <g class="k" transform="matrix(1, 0, 0, 1, 0.99, -30.85)">
                      <path class="c" d="M525.659,194.21a8.87,8.87,0,1,0,6.266,2.6A8.6,8.6,0,0,0,525.659,194.21Z" transform="translate(-441 -98.82)" />
                    </g>
                    <g class="j" transform="matrix(1, 0, 0, 1, 0.99, -30.85)">
                      <path class="c" d="M520.581,168.612a9.911,9.911,0,1,0-2.9,6.975A9.5,9.5,0,0,0,520.581,168.612Z" transform="translate(-437.9 -91.94)" />
                    </g>
                    <g class="i" transform="matrix(1, 0, 0, 1, 0.99, -30.85)">
                      <path class="c" d="M595.159,140.416a4.943,4.943,0,1,0-3.508-1.443A4.766,4.766,0,0,0,595.159,140.416Z" transform="translate(-455.22 -86.49)" />
                    </g>
                    <g class="h" transform="matrix(1, 0, 0, 1, 0.99, -30.85)">
                      <path class="c" d="M525.189,123.21a10.846,10.846,0,1,0,7.685,3.177A10.483,10.483,0,0,0,525.189,123.21Z" transform="translate(-440.53 -85.08)" />
                    </g>
                    <g class="g" transform="matrix(1, 0, 0, 1, 0.99, -30.85)">
                      <path class="c" d="M613.8,165.325a5.967,5.967,0,1,0,1.726,4.193,5.717,5.717,0,0,0-1.726-4.193Z" transform="translate(-457.81 -92.89)" />
                    </g>
                    <g class="f" transform="matrix(1, 0, 0, 1, 0.99, -30.85)">
                      <path class="c" d="M594.661,196.66a6.951,6.951,0,1,0,4.838,2.04,6.677,6.677,0,0,0-4.838-2.04Z" transform="translate(-454.72 -99.3)" />
                    </g>
                    <g class="e" transform="matrix(1, 0, 0, 1, 0.99, -30.85)">
                      <path class="c" d="M560.173,210.13a7.9,7.9,0,1,0,5.588,2.314,7.645,7.645,0,0,0-5.588-2.314Z" transform="translate(-447.87 -101.9)" />
                    </g>
                    <g class="d" transform="matrix(1, 0, 0, 1, 0.99, -30.85)">
                      <path class="c" d="M559.256,107.29a11.781,11.781,0,1,0,8.395,3.451,11.451,11.451,0,0,0-8.394-3.451Z" transform="translate(-446.96 -81.99)" />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div class="mt-10">
            <div class="font-24 font-weight-bold">
              {{ $t('dialog.payment_on_process') }}
            </div>
          </div>
          <div class="mt-2 font-14">
            {{ $t('dialog.desc_payment_on_process') }}
          </div>
          <div class="mt-10">
            <div class="font-14">
              {{ $t('dialog.order_no') }}
            </div>
            <div class="mt-2 primary--text font-weight-bold font-24">
              {{ order.order_no }}
            </div>
          </div>
          <div class="mt-10">
            <v-btn block color="#128C7E" height="40" class="border-radius-10 white--text text-none mb-4" @click="goContactSeller">
              <v-icon left>
                mdi-whatsapp
              </v-icon>
              {{ $t('order.contact_seller') }}
            </v-btn>
            <v-btn block color="white" height="40" class="border-radius-10 text-none font-weight-bold" @click="goListOrder">
              {{ $t('profile.my_order') }}
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="__menu-bottom menu-full">
      <v-col cols="12" sm="6" offset-sm="3" md="4" offset-md="4" class="pa-0">
        <v-btn color="primary" x-large block :class="[`text-none`, { 'd-none': isLastRefresh === false }]" @click="goToOrder(order.id)">
          {{ $t('dialog.refresh') }}
        </v-btn>
        <v-btn color="primary" :outlined="isLastRefresh === true ? true : false" x-large block :class="[`text-none`, { ' mt-3': isLastRefresh === true }]" @click="goToOrder(order.id)">
          {{ $t('dialog.detail_order') }}
        </v-btn>
      </v-col>
    </div>
  </v-container>
</template>

<script>
import { order } from '@/api/order'
export default {
  data() {
    return {
      order: {},
      loopInterval: 0,
      isLastRefresh: false,
      timerInterval: null
    }
  },
  mounted() {
    if (JSON.parse(localStorage.getItem(process.env.VUE_APP_COOKIE_KEY + '_order'))) {
      this.order = JSON.parse(localStorage.getItem(process.env.VUE_APP_COOKIE_KEY + '_order'))
    } else {
      this.$router.push({ name: 'home' }).catch(() => {})
    }
    this.$store.dispatch('remove_cart')
  },
  beforeDestroy() {
    clearInterval(this.timerInterval)
  },
  methods: {
    goContactSeller() {
      if (!this.$isEmpty(this.$store.state.outlet.selected_outlet)) {
        window.open('https://wa.me/' + this.$store.state.outlet.selected_outlet.setting.whatsapp, '_blank')
      }
    },
    goListOrder() {
      this.$router.push({ name: 'orders' }).catch(() => {})
    },
    goToOrder(id) {
      clearInterval(this.timerInterval)
      this.$router.push({ name: 'order', params: { id: id }}).catch(() => {})
    },
    async getDetailOrder(id) {
      try {
        const { data } = await order(id)
        this.order = data.data
        this.$store.dispatch('saveDataOrder', data.data)
        if (this.order.payment_log.global_status === 'X') {
          clearInterval(this.timerInterval)
          this.$router.push({ name: 'order_payment_failed' }).catch(() => {})
        } else if (this.order.payment_log.global_status === 'Z') {
          clearInterval(this.timerInterval)
          this.$router.push({ name: 'order_success' }).catch(() => {})
        }
      } catch (e) {
        return false
      }
    },
    refreshData(id) {
      const _this = this
      this.timerInterval = setInterval(function() {
        _this.loopInterval++
        _this.getDetailOrder(id)
        if (_this.loopInterval === 10) {
          clearInterval(_this.timerInterval)
          _this.isLastRefresh = true
        }
      }, 5000)
    }
  }
}
</script>
