var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'courier-card',
    {
      'courier-card-pending':
        !_vm.isDriver,
    },
    {
      'courier-card-hide-info':
        _vm.hideInfo,
    }
  ]},[_c('div',{staticClass:"courier-card_profile"},[_c('div',{staticClass:"courier-card_profile-desc"},[_c('img',{staticClass:"courier-card_profile-desc_img",attrs:{"src":require('@/assets/img/svg/avatar-driver.svg'),"alt":"driver"}}),(!_vm.isDriver)?_c('div',{staticClass:"courier-card_profile-pending"},[_vm._v(" Sedang mencari driver.. ")]):[_c('div',{staticClass:"courier-card_profile-desc_info"},[_c('div',{staticClass:"courier-card_profile-desc_info-top"},[_c('span',{staticClass:"courier-card_profile-desc_info-name"},[_vm._v(_vm._s(_vm.driverName))])]),_c('div',{staticClass:"courier-card_profile-desc_info-bottom"},[_c('span',{staticClass:"courier-card_profile-desc_info-transport"},[_vm._v(_vm._s(_vm.vehicleNumber)+" • "+_vm._s(_vm.vehicleType))])])]),_c('div',{staticClass:"courier-card_profile-desc_action"},[_c('v-btn',{staticClass:"courier-card_profile-desc_action-call",attrs:{"icon":"","color":"white"},on:{"click":_vm.callDriver}},[_c('v-icon',[_vm._v(" mdi-phone ")])],1)],1)]],2)]),(!_vm.hideInfo)?_c('div',{staticClass:"courier-card_desc"},[_c('span',{staticClass:"courier-card_desc-status"},[_vm._v(" "+_vm._s(_vm.statusShipper)+" ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }