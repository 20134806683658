<template>
  <div class="white">
    <div class="px-4 py-4">
      <!-- Title -->
      <div>
        <div class="font-18 font-weight-bold text-ellipsis-1">
          <span>{{ product?.name }}</span>
        </div>
        <div class="font-12 grey--text text--darken-1">
          <span v-if="productType === 'c'">{{ $t('product.contains_items', { item: commodity.length }) }}</span>
          <span v-if="productType === 'p'">{{ product?.category_name + ' • ' + product?.klasifikasi }}</span>
        </div>
      </div>

      <!-- Info Stock -->
      <div class="content-maxmin mt-4">
        <div v-if="productType === 'p'" class="title-content-maxmin d-flex" v-html="textInfoStock" />
        <div v-if="productType === 'c'" class="title-content-maxmin">
          {{ $t('product.limited_stock') }}
        </div>
        <div v-if="Number(product?.min_order) > 0 || Number(product?.max_order) > 0 && Number(product?.max_order) >= Number(product?.min_order)" class="subtitle-content-maxmin">
          <span v-if="Number(product?.min_order) > 0">
            {{ $t('product.min_buy') + Number(product?.min_order) }}
          </span>
          <span v-if="Number(product?.min_order) > 0 && Number(product?.max_order) > 0 && Number(product?.max_order) >= Number(product?.min_order)">
            -
          </span>
          <span v-if="Number(product?.max_order) > 0 && Number(product?.max_order) >= Number(product?.min_order)">
            {{ $t('product.max_buy') + Number(product?.max_order) }}
          </span>
        </div>
      </div>

      <!-- Variant -->

      <div v-if="productType === 'p' && product?.variants && product?.variants.length > 0" class="mt-4">
        <div class="d-flex">
          <div class="wrap-variant-list">
            <div v-for="(item, idx) in product.variants" :key="idx" :class="[`col-variant`, { 'active': form.product_variant_id === item.id, disabled: checkStockUnavailable }]" @click="!checkStockUnavailable ? handleSelectVariant(item) : ''">
              <div class="variant-iamge">
                <v-img :src="item.photo_lg" height="64px" width="64px" />
              </div>
              <div class="variant-content">
                <div class="variant-title">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Combo -->
      <div v-if="productType === 'c'" class="mt-4">
        <item-combo v-model="form.product_combo_items" :items="commodity" :is-stock-unavailable="checkStockUnavailable" />
      </div>

      <!-- Price -->
      <div class="mt-4">
        <span
          v-mask-money="price"
          :class="['font-weight-bold', 'font-24', {
            'grey--text text--darken-1': checkStockUnavailable,
            'blue--text': !checkStockUnavailable,
          }]"
        />
        <span v-if="market_price > 0" v-mask-money-no-currency="market_price" class="font-20 grey--text text-decoration-line-through ml-2" />
      </div>

      <!-- Alert -->
      <div v-if="checkStockUnavailable" class="alert-info alert-info-warning mt-4">
        <div class="mr-4 d-flex align-center">
          <img src="@/assets/img/png/icon_warning.png" alt="Warning" class="image-24">
        </div>
        <div class="font-14 text-ellipsis-2">
          Stok produk habis
        </div>
      </div>

      <!-- BTN Hapus -->
      <div class="mt-4">
        <v-btn
          block
          height="40"
          class="red--text font-16 font-weight-bold text-none"
          @click="handleDeleteProduct(index)"
        >
          {{ $t('product.delete_product') }}
        </v-btn>
      </div>
    </div>
    <!-- BTN SIMPAN -->
    <div class="mt-4">
      <v-btn
        block
        tile
        color="primary"
        height="72"
        class="font-16 font-weight-bold text-none"
        @click="handleSaveProduct"
      >
        {{ $t('product.save') }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import ItemCombo from '@/components/ItemCombo'
export default {
  name: 'DetailItemCart',
  components: {
    ItemCombo
  },
  props: {
    product: {
      type: Object,
      default: null
    },
    selectedProduct: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    },
    productType: {
      type: String,
      default: 'p'
    },
    onAlertDelete: {
      type: Function,
      default: null
    },
    onDelete: {
      type: Function,
      default: () => {}
    },
    onSave: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      form: {
        image: '',
        addons: [],
        has_material: 0,
        hold_qty: 0,
        is_out_stock: 0,
        klasifikasi_id: null,
        materials: [],
        name: '',
        non_service_charge: 0,
        non_taxable: 0,
        note: '',
        product_combo_id: null,
        product_combo_items: [],
        product_id: null,
        product_variant_id: null,
        product_variant_name: null,
        qty: 1,
        sell_price: 0,
        stock_qty: 0,
        sub_total: 0,
        track_inventory: 0,
        weight: 0,
        fweight: '',
        checked: false,
        min_order: 0,
        max_order: 0
      },
      item_addons: []
    }
  },
  computed: {
    price() {
      const detail_product = this.$props.product
      let price = 0
      if (!detail_product) {
        return price
      }
      if (this.$props.productType === 'p') {
        price = this.$getProductPrice(detail_product, 'sell_price', this.form.product_variant_id)
      } else {
        price = Number(detail_product.sell_price)
      }
      return Number(price)
    },
    market_price() {
      const detail_product = this.$props.product
      let price = 0
      if (!detail_product) {
        return price
      }
      if (this.$props.productType === 'p') {
        price = this.$getProductPrice(detail_product, 'market_price', this.form.product_variant_id)
      } else {
        price = Number(detail_product.market_price)
      }
      return Number(price)
    },
    commodity() {
      const detail_product = this.$props.product
      const selected_product = this.$props.selectedProduct
      if (!detail_product) {
        return []
      }
      const dataCommodity = this.$getCommodity(detail_product)
      if (!selected_product) {
        if (dataCommodity.length > 0) {
          dataCommodity.map((item) => {
            if (item.length > 0) {
              this.form.product_combo_items.push(item[0])
            }
          })
        }
      }
      return dataCommodity
    },
    weight() {
      const detail_product = this.$props.product
      const result = this.$getProductWeight(detail_product, this.form, this.$props.productType === 'p' ? 'product' : 'combo')
      return result
    },
    sub_total() {
      let total_addons = 0
      if (this.item_addons.length > 0) {
        for (let i = 0; i < this.item_addons.length; i++) {
          if (this.item_addons[i]) {
            total_addons = total_addons + Number(this.$props.product.addons[i].price)
          }
        }
      }
      return (Number(this.price) + total_addons) * this.form.qty
    },
    textInfoStock() {
      if (this.$props.product && this.$props.productType === 'p') {
        const text = this.$textInfoProductStock(this.$props.product, this.form)
        return text
      }
      return ''
    },
    checkStockUnavailable() {
      const item = this.$props.product
      if (!item || this.$isEmpty(item) || typeof item === 'undefined') {
        return true
      }
      if (typeof item.items !== 'undefined') {
        if (item.is_out_stock) {
          return true
        }
      } else {
        if (item.is_out_stock) {
          return true
        } else if (item.has_variant && typeof item.variants !== 'undefined') {
          let isDisabled = true
          for (let i = 0; i < item.variants.length; i++) {
            const variant = item.variants[i]
            if (item.track_inventory) {
              const stock_incart = this.$stockCart(variant.id, 'variant')
              if (!variant.is_out_stock && Number(variant.hold_qty) + stock_incart < Number(variant.stock_qty)) {
                isDisabled = false
                break
              }
            } else {
              if (!variant.is_out_stock) {
                isDisabled = false
                break
              }
            }
          }
          return isDisabled
        } else {
          if (item.track_inventory) {
            const stock_incart = this.$stockCart(item.id)
            if (item.is_out_stock || stock_incart + Number(item.hold_qty) >= Number(item.stock_qty)) {
              return true
            }
          } else {
            if (item.is_out_stock) {
              return true
            }
          }
        }
      }
      return false
    }
  },
  watch: {
    selectedProduct: {
      handler(newItem) {
        if (newItem) {
          this.form.product_combo_items = newItem.product_combo_items
          this.form.product_variant_id = newItem.product_variant_id
          this.form.product_variant_name = newItem.product_variant_name
          if (newItem.product_combo_items.length > 0) {
            this.form.product_combo_items = [...newItem.product_combo_items]
          }
          this.form.qty = newItem.qty
          this.form.hold_qty = newItem.hold_qty
          this.form.stock_qty = newItem.stock_qty
        }
      },
      deep: true
    }
  },
  mounted() {
    const selectedProduct = this.$props.selectedProduct
    if (selectedProduct || !this.$isEmpty(selectedProduct) || typeof selectedProduct !== 'undefined') {
      this.form.product_variant_id = selectedProduct.product_variant_id
      this.form.product_variant_name = selectedProduct.product_variant_name
      if (selectedProduct.product_combo_items.length > 0) {
        this.form.product_combo_items = [...selectedProduct.product_combo_items]
      }
      this.form.qty = selectedProduct.qty
      this.form.hold_qty = selectedProduct.hold_qty
      this.form.stock_qty = selectedProduct.stock_qty
    }
  },
  methods: {
    validateData(type = 'product') {
      const detail_product = this.$props.product
      const data = {
        valid: true,
        message: '',
        hold_qty: Number(detail_product.hold_qty),
        is_out_stock: detail_product.is_out_stock,
        stock_qty: Number(detail_product.stock_qty),
        image: detail_product.photo_lg
      }
      if (type === 'product') {
        if (detail_product.track_inventory) {
          if (detail_product.has_variant) {
            if (!this.form.product_variant_id) {
              data.valid = false
              data.message = this.$t('product.select_variant_first')
            } else {
              const stock_incart = this.$stockCart(this.form.product_variant_id, 'variant')
              const idx = detail_product.variants.findIndex((e) => e.id === this.form.product_variant_id)
              data.valid = Number(detail_product.variants[idx].stock_qty) - Number(detail_product.variants[idx].hold_qty) >= (Number(this.form.qty) + stock_incart) && !detail_product.variants[idx].is_out_stock
              data.message = this.$t('product.stock_is_not_enough', { data: detail_product.variants[idx].name })
              data.hold_qty = Number(detail_product.variants[idx].hold_qty)
              data.is_out_stock = detail_product.variants[idx].is_out_stock
              data.stock_qty = Number(detail_product.variants[idx].stock_qty)
              data.image = detail_product.variants[idx].photo_lg
            }
          } else {
            const stock_incart = this.$stockCart(detail_product.id)
            data.valid = Number(detail_product.stock_qty) - Number(detail_product.hold_qty) >= (Number(this.form.qty) + stock_incart) && !detail_product.is_out_stock
            data.message = this.$t('product.stock_is_not_enough', { data: detail_product.name })
          }
        } else {
          if (detail_product.has_variant) {
            if (!this.form.product_variant_id) {
              data.valid = false
              data.message = this.$t('product.select_variant_first')
            } else {
              const idx = detail_product.variants.findIndex((e) => e.id === this.form.product_variant_id)
              data.valid = !detail_product.variants[idx].is_out_stock
              data.message = this.$t('product.stock_is_not_enough', { data: detail_product.variants[idx].name })
              data.hold_qty = Number(detail_product.variants[idx].hold_qty)
              data.is_out_stock = detail_product.variants[idx].is_out_stock
              data.stock_qty = Number(detail_product.variants[idx].stock_qty)
              data.image = detail_product.variants[idx].photo_lg
            }
          } else {
            data.valid = !detail_product.is_out_stock
            data.message = this.$t('product.stock_is_not_enough', { data: detail_product.name })
          }
        }
      } else {
        if (this.form.product_combo_items.length > 0 && this.$props.product.items.length === this.form.product_combo_items.length) {
          for (let i = 0; i < this.form.product_combo_items.length; i++) {
            if (typeof this.form.product_combo_items[i] === 'undefined') {
              data.valid = false
              data.message = this.$t('v2.complete_the_required_fields')
              break
            } else {
              data.hold_qty = 0
              data.is_out_stock = this.$props.product.is_out_stock
              data.stock_qty = Number(this.$props.product.stock_qty)
              if (this.form.product_combo_items[i].published) {
                if (this.form.product_combo_items[i].track_inventory) {
                  if (this.form.product_combo_items[i].variant_id !== '') {
                    const stock_incart = this.$stockCart(this.form.product_combo_items[i].variant_id, 'variant')
                    // Tambahan jika variant nya sama maka tidak perlu ditambah dengan stock_incart atau dikurangi dengan qty current
                    if (this.form.product_combo_items[i].variant_id === this.$props.selectedProduct.product_combo_items[i].variant_id) {
                      data.valid = Number(this.form.product_combo_items[i].stock_qty) - Number(this.form.product_combo_items[i].hold_qty) >= ((Number(this.form.qty) * this.form.product_combo_items[i].qty) + (stock_incart - Number(this.form.qty))) && !this.form.product_combo_items[i].is_out_stock
                    } else {
                      data.valid = Number(this.form.product_combo_items[i].stock_qty) - Number(this.form.product_combo_items[i].hold_qty) >= ((Number(this.form.qty) * this.form.product_combo_items[i].qty) + stock_incart) && !this.form.product_combo_items[i].is_out_stock
                    }
                    data.message = this.$t('product.stock_is_not_enough', { data: this.form.product_combo_items[i].name })
                    if (data.valid === false) {
                      break
                    }
                  } else {
                    const stock_incart = this.$stockCart(this.form.product_combo_items[i].id)
                    // Tambahan jika id nya sama maka tidak perlu ditambah dengan stock_incart atau dikurangi dengan qty current
                    if (this.form.product_combo_items[i].id === this.$props.selectedProduct.product_combo_items[i].id) {
                      data.valid = Number(this.form.product_combo_items[i].stock_qty) - Number(this.form.product_combo_items[i].hold_qty) >= ((Number(this.form.qty) * this.form.product_combo_items[i].qty) + (stock_incart - Number(this.form.qty))) && !this.form.product_combo_items[i].is_out_stock
                    } else {
                      data.valid = Number(this.form.product_combo_items[i].stock_qty) - Number(this.form.product_combo_items[i].hold_qty) >= ((Number(this.form.qty) * this.form.product_combo_items[i].qty) + stock_incart) && !this.form.product_combo_items[i].is_out_stock
                    }
                    data.message = this.$t('product.stock_is_not_enough', { data: this.form.product_combo_items[i].name })
                    if (data.valid === false) {
                      break
                    }
                  }
                } else {
                  data.valid = !this.form.product_combo_items[i].is_out_stock
                  data.message = this.$t('product.stock_is_not_enough', { data: this.form.product_combo_items[i].name })
                  if (data.valid === false) {
                    break
                  }
                }
              } else {
                data.valid = false
                data.message = this.$t('v2.products_have_not_been_published', { item: this.form.product_combo_items[i].name })
                break
              }
            }
          }
        } else {
          data.valid = false
          data.message = this.$t('v2.complete_the_required_fields')
        }
      }
      return data
    },
    handleSelectVariant(item) {
      this.form.product_variant_id = item.id
      this.form.product_variant_name = item.name
    },
    handleDeleteProduct(index) {
      if (!this.$props.onAlertDelete) {
        this.$props.onDelete(index)
      } else {
        this.$props.onAlertDelete(index)
      }
    },
    async buildDataCart(newData) {
      const detail_product = this.$props.product
      const selected_product = this.$props.selectedProduct
      this.form.hold_qty = newData.hold_qty
      this.form.is_out_stock = newData.is_out_stock
      this.form.stock_qty = newData.stock_qty
      this.form.qty = selected_product.qty
      this.form.name = detail_product.name
      this.form.non_taxable = detail_product.non_taxable
      this.form.non_service_charge = typeof detail_product.non_service_charge !== 'undefined' ? detail_product.non_service_charge : 0
      if (this.$props.productType === 'p') {
        this.form.product_id = detail_product.id
        this.form.klasifikasi_id = detail_product.klasifikasi_id
        this.form.image = newData.image
      } else {
        this.form.product_combo_id = detail_product.id
        this.form.klasifikasi_id = null
        this.form.image = detail_product.photo_lg
      }
      this.form.sell_price = this.price
      this.form.sub_total = this.sub_total
      this.form.track_inventory = detail_product.track_inventory
      this.form.weight = this.weight.weight
      this.form.fweight = this.weight.fweight
      this.form.checked = selected_product.checked
      this.form.max_order = Number(detail_product.max_order)
      this.form.min_order = Number(detail_product.min_order)

      return this.form
    },
    async handleSaveProduct() {
      // Kondisi untuk product variant dan tanpa variant
      const selectedProduct = this.$props.selectedProduct
      // const detail_product = this.$props.product
      if (selectedProduct.product_variant_id || selectedProduct.product_id) {
        // Jika data tidak sama maka lakukan pengecekan dataValid
        if (selectedProduct.product_variant_id !== this.form.product_variant_id) {
          const dataValid = await this.validateData('product')
          if (!dataValid.valid) {
            this.$store.dispatch('showAlert', {
              type: 'error',
              show: true,
              message: dataValid.message
            })
          } else {
            this.$props.onSave(await this.buildDataCart(dataValid), this.$props.index, 'product')
          }
        } else {
          // // Jika data sama maka lakukan onSave tanpa pengecekan
          // selectedProduct.max_order = Number(detail_product.max_order)
          // selectedProduct.min_order = Number(detail_product.min_order)
          this.$props.onSave(await this.buildDataCart(selectedProduct), this.$props.index, 'product')
        }
      } else {
        // Kondisi untuk product combo
        if (!this.isComboStillSame(selectedProduct, this.form)) {
          const dataValid = await this.validateData('combo')
          if (!dataValid.valid) {
            this.$store.dispatch('showAlert', {
              type: 'error',
              show: true,
              message: dataValid.message
            })
          } else {
            this.$props.onSave(await this.buildDataCart(dataValid), this.$props.index, 'combo')
          }
        } else {
          // selectedProduct.max_order = Number(detail_product.max_order)
          // selectedProduct.min_order = Number(detail_product.min_order)
          this.$props.onSave(await this.buildDataCart(selectedProduct), this.$props.index, 'combo')
        }
      }
    },
    isComboInArray(arr, id, variant_id) {
      return arr.some(item => item.id === id && item.variant_id === variant_id)
    },
    isComboStillSame(selectedProduct, form) {
      // Jika jumlah elemen berbeda, maka combo sudah berubah
      if (selectedProduct.product_combo_items.length !== form.product_combo_items.length) {
        return false
      }

      // Periksa setiap elemen dalam selectedProduct.product_combo_items
      // apakah ada dalam this.form.product_combo_items berdasarkan id dan variant_id-nya
      for (const comboItem of selectedProduct.product_combo_items) {
        if (!this.isComboInArray(form.product_combo_items, comboItem.id, comboItem.variant_id)) {
          return false
        }
      }

      // Jika semua elemen dalam selectedProduct.product_combo_items ada dalam this.form.product_combo_items, maka combo masih sama
      return true
    },
    handleResetState() {
      this.form = {
        image: '',
        addons: [],
        has_material: 0,
        hold_qty: 0,
        is_out_stock: 0,
        klasifikasi_id: null,
        materials: [],
        name: '',
        non_service_charge: 0,
        non_taxable: 0,
        note: '',
        product_combo_id: null,
        product_combo_items: [],
        product_id: null,
        product_variant_id: null,
        product_variant_name: null,
        qty: 1,
        sell_price: 0,
        stock_qty: 0,
        sub_total: 0,
        track_inventory: 0,
        weight: 0,
        fweight: '',
        checked: false
      }
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
