<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" offset-sm="3" md="4" offset-md="4">
        <div class="text-center" style="padding-bottom: 150px;">
          <div class="mt-12 px-2">
            <div class="img-order">
              <svg xmlns="http://www.w3.org/2000/svg" width="220.431" height="162.595" viewBox="0 0 220.431 162.595" class="order_failed--img">
                <defs />
                <g transform="translate(0.009 -0.004)">
                  <path class="a" d="M61.83,433.31c3.481,13.452,10.843,25.08,22.477,30.717,24.187,11.715,78.734,5.673,119.444-1.262,21.059-3.587,39.568-13.486,53.014-27.417Z" transform="translate(-59.465 -355.352)" />
                  <path class="a" d="M297.759,58.928H275.422a1.755,1.755,0,1,1,0-3.493h3.863a1.755,1.755,0,1,1,0-3.493h-2.757a1.755,1.755,0,1,1,0-3.493h11.659a33.409,33.409,0,0,0-9.1-2.268c-31.791-3.693-43.141-1.25-47.145,1.26a47,47,0,0,1-16.34,6.48c-19.614,3.661-56.295,8.066-89.541-1.4C109.542,47.8,97.26,55.762,89.9,69.063H301.639a28.743,28.743,0,0,0-2.623-10.56,2.038,2.038,0,0,1-1.256.425Z" transform="translate(-81.91 -44.29)" />
                  <path class="a" d="M50.462,293.19a80.662,80.662,0,0,0,.866,23.469l196.114,6.491a83.2,83.2,0,0,0,13.626-18.8Zm77.566,16.742h-3.861a1.755,1.755,0,1,1,0,3.493H101.829a1.755,1.755,0,1,1,0-3.493h3.863a1.755,1.755,0,1,1,0-3.493h-2.759a1.755,1.755,0,1,1,0-3.493H125.27a1.755,1.755,0,1,1,0,3.493h2.757a1.755,1.755,0,1,1,0,3.493Z" transform="translate(-49.99 -243.31)" />
                  <path class="a" d="M271.757,168.5,57.648,173.209a71.037,71.037,0,0,0-5.128,20H268.006a76,76,0,0,0,3.11-12.751A54.584,54.584,0,0,0,271.757,168.5ZM95.992,183.94H92.13a1.756,1.756,0,1,1,0,3.495H69.793a1.756,1.756,0,1,1,0-3.495h3.863a1.755,1.755,0,1,1,0-3.493H70.9a1.755,1.755,0,1,1,0-3.493H93.235a1.755,1.755,0,1,1,0,3.493h2.757a1.755,1.755,0,1,1,0,3.493Z" transform="translate(-52.02 -143.607)" />
                  <ellipse class="a" cx="89.569" cy="8.24" rx="89.569" ry="8.24" transform="translate(19.651 140.042)" />
                  <path class="b" d="M888.594,800.146c-.583.022-3.9,0-3.9,0a1.8,1.8,0,0,1-.136-.16A4.9,4.9,0,0,1,888.594,800.146Z" transform="translate(-717.33 -648.26)" />
                  <path class="b" d="M935.9,800.146c-.583.022-3.9,0-3.9,0a1.8,1.8,0,0,1-.136-.16A4.9,4.9,0,0,1,935.9,800.146Z" transform="translate(-755.16 -648.26)" />
                  <path class="c" d="M855.306,427.211s.587-2.761-.2-3.639-.535-5.479.667-5.378,3.006,3.739,2.705,4.509-1.437,4.709-1.437,4.709Z" transform="translate(-693.416 -343.264)" />
                  <ellipse class="d" cx="1.803" cy="1.222" rx="1.803" ry="1.222" transform="translate(160.974 83.693)" />
                  <path class="e" d="M817.322,113.433s-16.873-1.016-14.883,10.807a2.874,2.874,0,0,0,1.5,3.038s.03-.876,1.729-.579a7.815,7.815,0,0,0,1.834.088,3.807,3.807,0,0,0,2.24-.926h0s4.743-1.958,6.589-9.711c0,0,1.365-1.691,1.31-2.124l-2.847,1.214a5.088,5.088,0,0,1,.2,3.761s-.092-3.685-.639-3.607c-.11.018-1.479.711-1.479.711s1.673,3.579.4,6.178c0,0,.479-4.409-.934-5.919l-2,1.17s1.956,3.7.629,6.711c0,0,.341-4.625-1.05-6.426l-1.817,1.417s1.838,3.643.717,6.144c0,0-.146-5.384-1.11-5.791a11.119,11.119,0,0,0-1.832,1.978s1.258,2.643.479,4.038c0,0-.479-3.587-.872-3.607a11.94,11.94,0,0,0-1.749,4.008,8.663,8.663,0,0,1,1.365-4.234,4.754,4.754,0,0,0-2.423,1.256s.246-1.681,2.805-1.828a10.959,10.959,0,0,1,1.659-1.912,10.4,10.4,0,0,0-4.1.473s1.363-1.585,4.571-.866l1.8-1.463s-3.362-.461-4.789.048c0,0,1.641-1.4,5.272-.381l1.954-1.168a17.914,17.914,0,0,0-4.577-.4s1.8-.972,5.148.082l1.4-.627s-2.1-.413-2.717-.477-.647-.236-.647-.236a7.284,7.284,0,0,1,3.954.459A17.812,17.812,0,0,0,817.322,113.433Z" transform="translate(-651.535 -99.557)" />
                  <path class="e" d="M763.876,87.25s-7.615-.461-6.725,4.881a1.3,1.3,0,0,0,.677,1.373s.012-.4.781-.261a3.618,3.618,0,0,0,.828.04,1.741,1.741,0,0,0,1.014-.419h0s2.142-.886,2.976-4.388c0,0,.617-.763.6-.96l-1.286.549a2.3,2.3,0,0,1,.094,1.7s-.042-1.665-.289-1.625c-.05,0-.669.321-.669.321a3.8,3.8,0,0,1,.186,2.791s.216-1.992-.421-2.675l-.906.529a4,4,0,0,1,.285,3.034s.152-2.09-.475-2.9l-.822.639a3.916,3.916,0,0,1,.325,2.775s-.066-2.433-.5-2.617a5.026,5.026,0,0,0-.828.894s.569,1.2.216,1.826c0,0-.216-1.621-.4-1.627a5.373,5.373,0,0,0-.79,1.8,3.9,3.9,0,0,1,.615-1.914,2.147,2.147,0,0,0-1.094.567s.11-.759,1.27-.826a5,5,0,0,1,.75-.864,4.7,4.7,0,0,0-1.854.214,2.094,2.094,0,0,1,2.066-.4l.8-.659a6.959,6.959,0,0,0-2.164.02,2.631,2.631,0,0,1,2.383-.17l.882-.529a8.019,8.019,0,0,0-2.068-.178,3.172,3.172,0,0,1,2.327.038l.631-.285s-.95-.186-1.226-.214-.295-.106-.295-.106a3.284,3.284,0,0,1,1.783.2A8.067,8.067,0,0,0,763.876,87.25Z" transform="translate(-615.395 -78.63)" />
                  <ellipse class="a" cx="5.396" cy="0.912" rx="5.396" ry="0.912" transform="translate(209.629 144.464)" />
                  <ellipse class="a" cx="5.396" cy="0.912" rx="5.396" ry="0.912" transform="translate(3.969 159.631)" />
                  <ellipse class="a" cx="5.396" cy="0.912" rx="5.396" ry="0.912" transform="translate(35.295 160.776)" />
                  <ellipse class="e" cx="8.058" cy="1.363" rx="8.058" ry="1.363" transform="translate(195.158 153.784)" />
                  <path class="e" d="M1051.027,792.58a2.351,2.351,0,0,0,.77-1.16,1.034,1.034,0,0,0-.537-1.18c-.493-.188-1.02.154-1.419.5a1.9,1.9,0,0,1-1.381.665,2.1,2.1,0,0,0,.649-1.966.826.826,0,0,0-.18-.4c-.275-.293-.77-.168-1.1.062a4.09,4.09,0,0,0-1.339,3.423,6.435,6.435,0,0,1-.018-1.4,1.449,1.449,0,0,0-.529-1.248,1.6,1.6,0,0,0-.8-.188,1.668,1.668,0,0,0-1.311.371,1.289,1.289,0,0,0,.052,1.6,5.2,5.2,0,0,0,1.354,1.086,3.007,3.007,0,0,1,.97.924.97.97,0,0,1,.072.166h2.936A8.175,8.175,0,0,0,1051.027,792.58Z" transform="translate(-844.482 -639.66)" />
                  <path class="e" d="M97.229,723.55s1.1,1.439-.509,3.607-2.936,4.008-2.4,5.362c0,0,2.427-4.036,4.409-4.094S99.4,725.977,97.229,723.55Z" transform="translate(-85.345 -587.432)" />
                  <path class="b" d="M97.488,723.55a1.8,1.8,0,0,1,.224.451c1.928,2.264,2.954,4.378,1.1,4.431-1.727.05-3.807,3.136-4.294,3.916a1.865,1.865,0,0,0,.06.178s2.427-4.036,4.409-4.094S99.66,725.977,97.488,723.55Z" transform="translate(-85.604 -587.432)" />
                  <path class="f" d="M118.525,731.39c0,.507-.058.918-.128.918s-.126-.4-.126-.918.07-.268.142-.268S118.525,730.883,118.525,731.39Z" transform="translate(-104.595 -593.439)" />
                  <path class="f" d="M119.536,734.338c-.445.242-.832.389-.866.327s.3-.307.745-.549.269-.066.3,0S119.989,734.1,119.536,734.338Z" transform="translate(-104.913 -595.781)" />
                  <path class="e" d="M70.442,723.55s-1.1,1.439.507,3.607,2.936,4.008,2.4,5.362c0,0-2.429-4.036-4.409-4.094S68.268,725.977,70.442,723.55Z" transform="translate(-64.373 -587.432)" />
                  <path class="b" d="M70.43,723.55a1.729,1.729,0,0,0-.224.451c-1.928,2.264-2.956,4.378-1.1,4.431,1.725.05,3.807,3.136,4.292,3.916a1.851,1.851,0,0,1-.058.178s-2.429-4.036-4.409-4.094S68.256,725.977,70.43,723.55Z" transform="translate(-64.362 -587.432)" />
                  <path class="f" d="M70.1,731.39c0,.507.056.918.126.918s.126-.4.126-.918-.07-.268-.14-.268S70.1,730.883,70.1,731.39Z" transform="translate(-66.077 -593.439)" />
                  <path class="f" d="M65.514,734.338c.445.242.834.389.868.327s-.3-.307-.745-.549-.271-.066-.3,0S65.07,734.1,65.514,734.338Z" transform="translate(-62.195 -595.781)" />
                  <path class="g" d="M71.85,760.751s3.078-.094,4.008-.755,4.735-1.449,4.966-.4,4.625,5.27,1.15,5.3-8.074-.543-9-1.106S71.85,760.751,71.85,760.751Z" transform="translate(-67.477 -615.846)" />
                  <path class="h" d="M82.032,771.167c-3.475.028-8.074-.543-9-1.106-.705-.429-.986-1.97-1.08-2.681h-.1s.2,2.483,1.12,3.046,5.525,1.134,9,1.106c1,0,1.349-.367,1.331-.894C83.16,770.961,82.779,771.161,82.032,771.167Z" transform="translate(-67.477 -622.479)" />
                  <circle class="i" cx="61.819" cy="61.819" r="61.819" transform="translate(27.172 29.318)" />
                  <path class="j" d="M511.439,529.323c-4.861-.732-10.667-.584-18.9-.763-6.039-.132-14.188-.825-21.005.24,5.731-4.236,12.484-3.991,19.553-3.991C498.351,524.809,505.758,524.814,511.439,529.323Z" transform="translate(-387.066 -419.894)" />
                  <circle class="k" cx="14" cy="14" r="14" transform="translate(67.192 56.599)" />
                  <circle class="k" cx="14" cy="14" r="14" transform="translate(105.192 56.599)" />
                  <circle class="j" cx="5.5" cy="5.5" r="5.5" transform="translate(75.192 70.599)" />
                  <circle class="j" cx="5.5" cy="5.5" r="5.5" transform="translate(114.192 70.599)" />
                  <path class="l" d="M59.294,56.6a52,52,0,0,0,15.625-.478,27.156,27.156,0,0,0,11.336-4.794" transform="matrix(0.951, -0.309, 0.309, 0.951, -11.171, 27.139)" />
                  <path class="l" d="M86.256,56.6a52,52,0,0,1-15.625-.478,27.157,27.157,0,0,1-11.336-4.794" transform="matrix(0.966, 0.259, -0.259, 0.966, 68.495, -14.99)" />
                </g>
              </svg>
            </div>
          </div>
          <div class="mt-10">
            <div class="font-24 font-weight-bold">
              {{ $t('dialog.failed_order') }}
            </div>
          </div>
          <div class="mt-2 font-14">
            {{ $t('dialog.sorry_your_order_failed') }}
          </div>
          <div class="mt-10">
            <div class="font-14">
              {{ $t('dialog.order_no') }}
            </div>
            <div class="mt-2 primary--text font-weight-bold font-24">
              {{ order.order_no }}
            </div>
          </div>
          <div class="mt-10">
            <v-btn block color="#128C7E" height="40" class="border-radius-10 white--text text-none mb-4" @click="goContactSeller">
              <v-icon left>
                mdi-whatsapp
              </v-icon>
              {{ $t('order.contact_seller') }}
            </v-btn>
            <v-btn block color="white" height="40" class="border-radius-10 text-none font-weight-bold" @click="goListOrder">
              {{ $t('profile.my_order') }}
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="__menu-bottom menu-full">
      <v-col cols="12" sm="6" offset-sm="3" md="4" offset-md="4" class="pa-0">
        <v-btn color="primary" x-large block class="text-none" @click="goOrderDetail()">
          {{ $t('dialog.detail_order') }}
        </v-btn>
      </v-col>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      order: {}
    }
  },
  mounted() {
    if (JSON.parse(localStorage.getItem(process.env.VUE_APP_COOKIE_KEY + '_order'))) {
      this.order = JSON.parse(localStorage.getItem(process.env.VUE_APP_COOKIE_KEY + '_order'))
    } else {
      this.$router.push({ name: 'home' }).catch(() => {})
    }
    this.$store.dispatch('remove_cart')
  },
  methods: {
    goContactSeller() {
      if (!this.$isEmpty(this.$store.state.outlet.selected_outlet)) {
        window.open('https://wa.me/' + this.$store.state.outlet.selected_outlet.setting.whatsapp, '_blank')
      }
    },
    goListOrder() {
      this.$router.push({ name: 'orders' }).catch(() => {})
    },
    goOrderDetail() {
      this.$router.push({ name: 'order', params: { id: this.order.id }}).catch(() => {})
      this.$store.dispatch('remove_order')
    }
  }
}
</script>
