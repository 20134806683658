<template>
  <div class="banner-store__wrap" :class="storeClosed === true ? 'closed' : null">
    <div class="banner-store__inner" :class="containerFullScreenOnly">
      <img class="banner-store__img" :src="logo || imgStore" alt="logo-store">
      <span v-if="title" class="mt-4 title font-weight-bold">
        {{ title }}
      </span>
      <span v-if="address" class="mt-2 caption text-center">
        {{ address }}
      </span>
    </div>
    <div class="banner-store__circle" />
  </div>
</template>

<script>
import _imgStore from '@/assets/img/png/store.png'

export default {
  name: 'BannerStore',
  props: {
    logo: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    address: {
      type: String,
      default: ''
    },
    storeClosed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imgStore: _imgStore
    }
  },
  computed: {
    containerFullScreenOnly() {
      return this.$vuetify.breakpoint.mobile ? 'container col-lg-6 col-md-8 col-sm-10 col-12' : ''
    }
  }
}
</script>

<style scoped lang="scss">
.banner-store__wrap {
  width: 100%;
  min-height: 200px;
  height: fit-content;
  overflow: hidden;
  background-color: #FEE8E6;
  position: relative;
  display: flex;
  flex-direction: column;
}

.banner-store__inner {
  padding: 15px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}

.banner-store__img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  object-position: center;
  background-color: #FFFFFF;
  border-radius: 10px;
  z-index: 2;
}

.banner-store__circle {
  width: 377px;
  height: 377px;
  background-color: rgba($color: #F44336, $alpha: 0.1);
  border-radius: 100%;
  position: absolute;
  z-index: 1;
  right: -161px;
  top: 0;
}

.banner-store__wrap.closed {
  background-color: #CECECE;
  .banner-store__circle {
    background-color: rgba($color: #767676, $alpha: 0.1);
  }
}
</style>
