<template lang="html">
  <div>
    <combo-detail v-model="product" :data-in-cart="cart" :click-addcart="addCart" />
  </div>
</template>
<script>
import ComboDetail from '@/components/product/ComboDetail'
import { getProductComboDetail } from '@/api/product'
export default {
  components: {
    ComboDetail
  },
  data() {
    return {
      product: {
        item: {},
        type: 'combo'
      },
      cart: {
        item: [],
        notes: '',
        currency_id: 'IDR'
      }
      // shipping: {
      //   shipping_address: '',
      //   shipping_address_is_different: 1,
      //   without_shipping: 1
      // }
    }
  },
  mounted() {
    this.getDataCart()
    this.getProduct()
  },
  methods: {
    getDataCart() {
      const LOCAL_DATA_CART = localStorage.getItem(process.env.VUE_APP_COOKIE_KEY + '_data_cart')
      if (LOCAL_DATA_CART) {
        this.cart = JSON.parse(LOCAL_DATA_CART)
      }
    },
    async getProduct() {
      try {
        const { data } = await getProductComboDetail(this.$route.params.id)
        this.product.item = data.data
      } catch (e) {
        this.product = {
          item: {},
          type: 'combo'
        }
        this.$store.dispatch('showAlert', {
          type: 'error',
          show: true,
          message: e.message
        })
      }
    },
    async addCart(form, type) {
      const index = this.cart.item.findIndex((e) => {
        let value = false
        if ((typeof e.product_type === 'undefined' || e.product_type !== 'point') && form.product_id) {
          if (form.product_variant_id) {
            if (e.product_id === form.product_id && e.product_variant_id === form.product_variant_id) {
              if (e.addons.length > 0 || form.addons.length > 0) {
                value = false
              } else {
                value = true
              }
            }
          } else {
            if (e.product_id === form.product_id) {
              if (e.addons.length > 0 || form.addons.length > 0) {
                value = false
              } else {
                value = true
              }
            }
          }
        }
        return value
      })

      if (index === -1) {
        await this.cart.item.push({ ...form })
      } else {
        this.cart.item[index].qty = await this.cart.item[index].qty + form.qty
        this.cart.item[index].sub_total = await this.cart.item[index].sub_total + form.sub_total
      }
      await this.$store.dispatch('saveDataCart', this.cart)
      // await this.$store.dispatch('saveCartV2', this.cart)
    }
  }
}
</script>
