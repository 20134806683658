<template>
  <div
    :class="[
      'courier-card',
      {
        'courier-card-pending':
          !isDriver,
      },
      {
        'courier-card-hide-info':
          hideInfo,
      }
    ]"
  >
    <div class="courier-card_profile">
      <div class="courier-card_profile-desc">
        <img
          class="courier-card_profile-desc_img"
          :src="require('@/assets/img/svg/avatar-driver.svg')"
          alt="driver"
        >
        <div v-if="!isDriver" class="courier-card_profile-pending">
          Sedang mencari driver..
        </div>
        <template v-else>
          <div class="courier-card_profile-desc_info">
            <div class="courier-card_profile-desc_info-top">
              <span class="courier-card_profile-desc_info-name">{{
                driverName
              }}</span>
            <!-- Disable Rating: Belum ada dukungan dari BE -->
            <!-- <div class="courier-card_profile-desc_info-rate">
              <v-icon class="courier-card_profile-desc_info-rate_icon">
                mdi-star
              </v-icon>
              <span class="courier-card_profile-desc_info-rate_number">5</span>
            </div> -->
            </div>
            <div class="courier-card_profile-desc_info-bottom">
              <span
                class="courier-card_profile-desc_info-transport"
              >{{ vehicleNumber }} • {{ vehicleType }}</span>
            </div>
          </div>
          <div class="courier-card_profile-desc_action">
            <v-btn
              icon
              class="courier-card_profile-desc_action-call"
              color="white"
              @click="callDriver"
            >
              <v-icon>
                mdi-phone
              </v-icon>
            </v-btn>
          </div>
        </template>
      </div>
    </div>
    <div v-if="!hideInfo" class="courier-card_desc">
      <span class="courier-card_desc-status">
        {{ statusShipper }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CourierCard',
  props: {
    driverName: {
      default: '',
      type: String
    },
    driverPhone: {
      default: '',
      type: String
    },
    vehicleNumber: {
      default: '',
      type: String
    },
    vehicleType: {
      default: '',
      type: String
    },
    statusShipper: {
      default: '',
      type: String
    },
    statusCode: {
      default: 0,
      type: Number
    },
    isDriver: {
      default: 0,
      type: Number
    },
    hideInfo: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    callDriver() {
      window.open(`tel:${this.driverPhone}`, '_self')
    }
  }
}
</script>
<style lang="scss" scoped>
.courier-card {
  &_profile {
    background-color: #1fad1f;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 8px 16px;
    &-desc {
      display: flex;
      align-items: center;
      gap: 8px;
      &_img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        object-fit: cover;
      }
      &_info {
        flex: 1;
        &-top {
          display: inline-flex;
          gap: 8px;
        }
        &-name {
          color: #ffffff;
          font-size: 14px;
        }
        &-rate {
          display: flex;
          align-items: center;
          &_icon.v-icon {
            color: #ffd91a;
            font-size: 16px;
          }
          &_number {
            color: #ffffff;
            font-size: 14px;
          }
        }
        &-bottom {
          width: 100%;
        }
        &-transport {
          color: white;
          font-size: 14px;
        }
      }
      &_action {
        display: flex;
        align-items: center;
        &-call.v-btn {
          width: 40px;
          height: 40px;
        }
      }
    }
    &-pending{
      display: none;
    }
  }
  &_desc {
    background-color: #e9fbe9;
    padding: 8px 16px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    &-status {
      font-size: 14px;
    }
  }
}
.courier-card.courier-card-hide-info {
  .courier-card_profile {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
.courier-card.courier-card-pending {
  .courier-card_profile {
    background-color: #E9FBE9;
    &-pending{
      display: block;
      font-size: 16px;
      font-weight: 600;
      margin-left: 8px;
    }
    &-desc_info {
      display: none;
    }
    &-desc_action {
      display: none;
    }
  }
}
</style>
