import { render, staticRenderFns } from "./DetailCombo.vue?vue&type=template&id=ce5ccd52&lang=html&"
import script from "./DetailCombo.vue?vue&type=script&lang=js&"
export * from "./DetailCombo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports