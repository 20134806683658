<template lang="html">
  <v-snackbar v-model="show" top :class="['snackbar-soft z-index-9999', { 'snackbar-success-soft': type === 'success', 'snackbar-error-soft': type === 'error' }]" timeout="3000">
    <div class="d-flex">
      <div class="mr-4 d-flex align-center">
        <img v-if="type === 'success'" src="@/assets/img/png/icon_success.png" alt="Success" class="image-24">
        <img v-else-if="type === 'error'" src="@/assets/img/png/icon_warning.png" alt="Warning" class="image-24">
      </div>
      <div class="font-18 font-weight-700 line-height-normal" :class="isEllipsisMessage ? 'text-ellipsis-2' : ''">
        {{ message }}
      </div>
    </div>
  </v-snackbar>
</template>
<script>
export default {
  name: 'Alert',
  props: {
    value: {
      default: false,
      type: Boolean
    },
    message: {
      default: '',
      type: String
    },
    type: {
      default: 'success',
      type: String
    },
    isEllipsisMessage: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    'show': {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
<style>
.v-snack.snackbar-soft{
  width: calc(100% - 16px);
}
.v-snack.snackbar-soft > .v-snack__wrapper  > .v-snack__content{
  padding: 24px !important;
  min-height: auto;
}
.v-snack.snackbar-success-soft > .v-snack__wrapper {
  background-color: #E9FBE9 !important;
  box-shadow: 0px 3px 6px 0px #0000001F !important;
  color: #1FAD1F !important;
  border-radius: 10px !important;
}
.v-snack.snackbar-error-soft > .v-snack__wrapper {
  background-color: #FFF2E3 !important;
  box-shadow: 0px 3px 6px 0px #0000001F !important;
  color: #FF931E !important;
  border-radius: 10px !important;
}
</style>
