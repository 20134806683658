<template lang="html">
  <v-dialog v-model="showDialog" persistent content-class="border-radius-10" max-width="370">
    <v-card v-if="!$isEmpty($store.state.outlet.selected_outlet)" class="p-relative">
      <div class="d-flex align-center py-3 px-4">
        <div class="d-flex align-center">
          <div class="font-20 font-weight-bold">
            {{ $t('product.operational_hour') }}
          </div>
        </div>
        <v-spacer />
        <v-btn icon @click="closeDialog">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <div class="custom-scroll-bar container-freeongkir">
        <div class="pb-6">
          <div v-for="(item, idx) in $store.state.outlet.selected_outlet.working_hours" :key="idx" class="list-hours justify-space-between">
            <div class="d-flex align-center">
              <div class="item-tnc-icon mr-2">
                <v-icon :color="typeof item.times !== 'undefined' && item.times.length > 0 ? '#1FAD1F' : '#A7A7A7'">
                  mdi-circle
                </v-icon>
              </div>
              <div class="item-tnc-content" :style="{ color: typeof item.times !== 'undefined' && item.times.length > 0 ? '#272727' : '#A7A7A7' }">
                {{ item.day_name }}
              </div>
            </div>
            <div class="item-tnc-action">
              <div v-for="(hour, index) in item.times" :key="index">
                {{ hour.fstart_time }} - {{ hour.fend_time }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'DialogShopOpenHours',
  props: {
    value: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit('input', false)
    }
  }
}
</script>
