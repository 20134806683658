<template>
  <v-dialog v-model="open" persistent max-width="380">
    <v-card class="confirm-alert">
      <v-card-title v-if="title" class="confirm-alert__title">
        {{ title }}
      </v-card-title>
      <v-card-text v-if="description" class="confirm-alert__desc" v-html="description" />
      <v-card-actions class="confirm-alert__actions">
        <v-btn v-if="showCloseButton" class="confirm-alert__actions-close" @click="onClose">
          {{ closeButtonText || $t('dialog.close') }}
        </v-btn>
        <v-btn v-if="showDenyButton" class="confirm-alert__actions-deny" @click="onDeny">
          {{ denyButtonText || $t('dialog.cancel') }}
        </v-btn>
        <v-btn v-if="showConfirmButton" :loading="isLoadingConfirmBtn" color="primary" class="confirm-alert__actions-confirm" @click="onConfirm">
          {{ confirmButtonText || $t('dialog.yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ConfirmationAlert',
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    open: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function,
      default: () => {}
    },
    onDeny: {
      type: Function,
      default: () => {}
    },
    onConfirm: {
      type: Function,
      default: () => {}
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    showDenyButton: {
      type: Boolean,
      default: true
    },
    showConfirmButton: {
      type: Boolean,
      default: true
    },
    closeButtonText: {
      type: String,
      default: ''
    },
    denyButtonText: {
      type: String,
      default: ''
    },
    confirmButtonText: {
      type: String,
      default: ''
    },
    isLoadingConfirmBtn: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.confirm-alert {
  .v-card__title.confirm-alert__title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    display: block;
  }
  .v-card__text.confirm-alert__desc {
    text-align: center;
    color: #272727;
  }
  .v-card__actions.confirm-alert__actions {
    justify-content: center;
    padding-bottom: 16px;
    .v-btn.confirm-alert__actions-close,
    .v-btn.confirm-alert__actions-deny,
    .v-btn.confirm-alert__actions-confirm {
      flex: 1;
      text-transform: unset;
    }
  }
}
</style>
