<template lang="html">
  <div>
    <v-dialog
      v-model="open"
      persistent
      content-class="border-radius-10"
      :fullscreen="$vuetify.breakpoint.mobile"
      :transition="$vuetify.breakpoint.mobile ? 'dialog-bottom-transition' : ''"
      :width="$vuetify.breakpoint.mobile ? '' : '500'"
    >
      <v-card class="p-relative">
        <v-btn icon class="btn-close-dialog" @click="closeDialog">
          <v-icon color="#272727">
            mdi-close
          </v-icon>
        </v-btn>
        <v-btn icon class="btn-outlet-dialog-whatsapp" @click="goToWhatsApp">
          <v-icon color="#1FAD1F">
            mdi-whatsapp
          </v-icon>
        </v-btn>
        <BannerStore :logo="selectedOutlet.logo" :title="selectedOutlet.name" :address="selectedOutlet.address" :store-closed="_handleStoreClose" />
        <div v-if="selectedOutlet.fstore_status === _fstoreStatus._OFF" class="mt-4 px-4" :class="containerFullScreenOnly">
          <div class="dialog-list-outlet_store-off__alert">
            <span class="dialog-list-outlet_store-off__alert-title mt-2">{{ $t('dialog.alert_store_off_title') }}</span>
            <span class="dialog-list-outlet_store-off__alert-caption mt-2">{{ $t('dialog.alert_store_off_description') }}</span>
            <span class="dialog-list-outlet_store-off__alert-time mt-2">
              {{ _reformatDate }}
            </span>
          </div>
        </div>
        <div class="px-4 mt-4" :class="containerFullScreenOnly">
          <span class="font-12 mb-1">
            {{ $t('merchant.other_branch') }}
          </span>
        </div>
        <div v-if="outlets.length > 0" class="my-4" :class="`${containerFullScreenOnly} px-0`">
          <ItemBranchStore
            v-for="(outlet, index) in outlets"
            :key="outlet.id"
            v-intersect="index === lastOutletIndex ? onIntersect : undefined"
            :logo="outlet.logo"
            :store-name="outlet.name"
            :store-time="outlet.fstore_status === _fstoreStatus._OFF ? '00:00 - 00:00' : handleJoinMultipleTime(outlet.shop_hours_today)"
            :store-address="outlet.address"
            :store-distance="outlet.location_distance.text"
            :store-status="outlet.fstore_status"
            :on-click="() => handleClickOutlet(outlet)"
          />
          <div v-if="loadingInfiniteScroll" class="d-flex align-center justify-center px-4 py-4">
            <v-progress-circular indeterminate color="blue" size="24" />
          </div>
        </div>
        <div class="mt-4 text-center py-4 px-4" :class="containerFullScreenOnly">
          <router-link :to="{ name: 'terms_conditions' }" class="primary--text font-14 font-weight-bold text-d-none">
            {{ $t('merchant.merchant_tnc') }}
          </router-link>
        </div>
      </v-card>
    </v-dialog>

    <ConfirmationAlert
      :title="$t('merchant.question_confirm_move_branch', { store: tmp_selected_outlet?.name })"
      :description="$t('merchant.desc_confirm_move_branch')"
      :open="popup_warning_change_store"
      :show-close-button="false"
      :confirm-button-text="$t('dialog.yes_continue')"
      :on-deny="handleDenyChangeOutlet"
      :on-confirm="handleConfirmChangeOutlet"
    />
  </div>
</template>
<script>
import { listOutlet } from '@/api/outlet'
import BannerStore from '@/components/BannerStore.vue'
import { formatMonthToString } from '@/utils/helpers'
import ItemBranchStore from '@/components/ItemBranchStore.vue'
import { FSTORE_STATUS, handleStoreClose, getNextWorkTime } from '@/utils/storeOperational'
import ConfirmationAlert from '@/components/alert/ConfirmationAlert.vue'

export default {
  components: {
    BannerStore,
    ItemBranchStore,
    ConfirmationAlert
  },
  props: {
    open: {
      default: false,
      type: Boolean
    },
    item: {
      default: () => [],
      type: Array
    },
    onClose: {
      default: () => {},
      type: Function
    }
  },
  data() {
    return {
      url_banner: require('@/assets/img/svg/bg_list_outlet.svg'),
      outlets: [],
      outletsMeta: null,
      page: 1,
      per_page: 15,
      loadingInfiniteScroll: false,
      popup_warning_change_store: false,
      tmp_selected_outlet: null
    }
  },
  computed: {
    selectedOutlet() {
      return this.$store.getters.selectedOutlet
    },
    lastOutletIndex() {
      return this.outlets.length - 1
    },
    _fstoreStatus() {
      return FSTORE_STATUS
    },
    _handleStoreClose() {
      return handleStoreClose(this.$store.getters.selectedOutlet.fstore_status)
    },
    _reformatDate() {
      const date = new Date(this.getWorkingHour().date)
      const getDate = date.getDate()
      const getMonth = formatMonthToString(date.getMonth())
      const getFullYear = date.getFullYear()
      return `${getDate} ${getMonth} ${getFullYear}, ${this.getWorkingHour().times[0].fstart_time}`
    },
    containerFullScreenOnly() {
      return this.$vuetify.breakpoint.mobile ? 'container col-lg-6 col-md-8 col-sm-10 col-12 py-0' : ''
    }
  },
  mounted() {
    this.fetchListOutlet()
  },
  methods: {
    closeDialog() {
      this.$props.onClose()
    },
    getWorkingHour() {
      return getNextWorkTime(this.selectedOutlet.store_open_date_tomorrow, this.selectedOutlet.store_closing_date_today)
    },
    handleJoinMultipleTime(shop_hours_today) {
      if (shop_hours_today.length > 0 || shop_hours_today) {
        return shop_hours_today.map(time => `${time.fstart_time} - ${time.fend_time}`).join(', ')
      }
      return '00:00 - 00:00'
    },
    goToWhatsApp() {
      let chatWA = null
      if (!this.$isEmpty(this.$store.state.outlet.selected_outlet)) {
        if (!this.$isEmpty(this.$store.state.outlet.selected_outlet.setting)) {
          chatWA = this.$store.state.outlet.selected_outlet.setting.whatsapp
        }
        if (chatWA) {
          this.$goToWhatsApp(chatWA, 'window')
        } else {
          this.$store.dispatch('showAlert', {
            type: 'error',
            show: true,
            message: this.$t('account.whatsapp_not_been_set')
          })
        }
      }
    },
    onIntersect(entries, observer) {
      if (entries[0].isIntersecting) {
        if (!this.loading && this.outletsMeta.last_page !== this.page) {
          this.loadingInfiniteScroll = true
          this.page += 1
          this.fetchListOutlet()
        }
      }
    },
    async getCoords() {
      const pos = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject)
      })

      return {
        long: pos.coords.longitude,
        lat: pos.coords.latitude
      }
    },
    async fetchListOutlet() {
      try {
        const coords = await this.getCoords()
        const params = {
          origin_lat: coords.lat,
          origin_lon: coords.long,
          page: this.page,
          per_page: this.per_page
        }
        const res = await listOutlet(params)
        if (this.outlets.length > 0 && this.outlets.length < res.data.meta.total) {
          this.outlets = [...this.outlets, ...res.data.data]
        } else {
          this.outlets = res.data.data
        }
        this.outletsMeta = res.data.meta
      } catch (error) {
        this.$store.dispatch('showAlert', {
          type: 'error',
          show: true,
          message: error.message
        })
      } finally {
        this.loadingInfiniteScroll = false
      }
    },
    handleClickOutlet(outlet) {
      this.tmp_selected_outlet = outlet
      const getCart = this.$store.getters.dataCart
      if (getCart) {
        this.popup_warning_change_store = true
      } else {
        this.handleConfirmChangeOutlet(false)
      }
    },
    handleDenyChangeOutlet() {
      const getCart = this.$store.getters.dataCart
      if (getCart) {
        this.popup_warning_change_store = false
      }
      this.tmp_selected_outlet = null
    },
    handleConfirmChangeOutlet(cartNotNull = true) {
      const dataBranch = { url_id: this.tmp_selected_outlet.url_id, id: this.tmp_selected_outlet.id }
      this.$store.commit('SET_OUTLET', this.tmp_selected_outlet)
      this.$store.commit('SET_SELECTED_BRANCH', dataBranch)
      // Apakah ada cart pada localstorage, jika ada maka hapus
      if (cartNotNull) {
        localStorage.removeItem(`${process.env.VUE_APP_COOKIE_KEY}_data_cart`)
        this.$store.commit('REMOVE_TEMPORARY_SHIPPING')
      }
      localStorage.removeItem(`${process.env.VUE_APP_COOKIE_KEY}_order`)
      window.location.reload()
    }
  }
}
</script>
