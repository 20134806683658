<template lang="html">
  <div :class="[!isRelated ? 'row-list-product' : 'margin-product']">
    <v-row>
      <v-col cols="12" class="py-2">
        <div class="font-18 font-weight-bold mb-4">
          {{ title }}
        </div>
        <v-row>
          <template v-if="isRelated">
            <v-col :cols="12" class="p-relative">
              <div ref="el" class="wrap-list-product-related" @mousedown="onMouseDown" @mouseup="onMouseUp">
                <div class="content-list-product-related">
                  <div v-for="(item, idx) in products" :key="idx" class="item-product-related">
                    <v-card :class="[`product-thumbnail`, { 'product-thumbnail-out-stock': checkStock(item) }]" :disabled="checkStock(item)" @click="funcShow(item)">
                      <div v-if="item.sell_discount_percentage" class="tag-discount">
                        <div class="wrap-tag-discount">
                          <span class="white--text">
                            {{ item.sell_discount_percentage }}
                          </span>
                          <img :src="require('@/assets/img/svg/disc.svg')">
                        </div>
                      </div>
                      <v-img :src="item.photo_md" dark height="171px" :class="['flex-grow-0', { 'greyscale-100' : checkStock(item) }]" />
                      <div class="product-thumbnail__content">
                        <div class="product-price">
                          <div>
                            {{ item.fsell_price }}
                          </div>
                          <div v-if="Number(item.market_price) !== 0" v-mask-money-no-currency="item.market_price" class="product-price-disc" />
                        </div>
                        <div class="font-14 text-overflow-height">
                          {{ item.name }}
                        </div>
                      </div>
                      <div class="product-action flex-grow-0">
                        <div v-if="checkStock(item)" class="product-tag-special-type out-of-stock">
                          {{ $t('product.out_of_stock') }}
                        </div>
                        <div v-else-if="item.format_type_special" :class="[`product-tag-special-type`, { 'sale': item.format_type_special === 'Sale' }]">
                          {{ item.format_type_special }}
                        </div>
                        <v-spacer />
                        <div v-if="item.count_sold_item !== 0" class="font-12">
                          {{ item.fcount_sold_item }} {{ $t('product.sold') }}
                        </div>
                      </div>
                    </v-card>
                  </div>
                </div>
              </div>
            </v-col>
          </template>
          <template v-else>
            <v-col v-for="(item, idx) in products" :key="idx" :cols="6" :xs="4" :md="3" :lg="2">
              <v-card :class="[`product-thumbnail`, { 'product-thumbnail-out-stock': checkStock(item) }]" :disabled="checkStock(item)" @click="funcShow(item)">
                <div v-if="item.sell_discount_percentage" class="tag-discount">
                  <div class="wrap-tag-discount">
                    <span class="white--text">
                      {{ item.sell_discount_percentage }}
                    </span>
                    <img :src="require('@/assets/img/svg/disc.svg')">
                  </div>
                </div>
                <v-img :src="item.photo_md" dark height="171px" :class="['flex-grow-0', { 'greyscale-100' : checkStock(item) }]" />
                <div class="product-thumbnail__content">
                  <div class="product-price">
                    <div>
                      {{ item.fsell_price }}
                    </div>
                    <div v-if="Number(item.market_price) !== 0" v-mask-money-no-currency="item.market_price" class="product-price-disc" />
                  </div>
                  <div class="font-14 text-overflow-height">
                    {{ item.name }}
                  </div>
                </div>
                <div class="product-action flex-grow-0">
                  <div v-if="checkStock(item)" class="product-tag-special-type out-of-stock">
                    {{ $t('product.out_of_stock') }}
                  </div>
                  <div v-else-if="item.format_type_special" :class="[`product-tag-special-type`, { 'sale': item.format_type_special === 'Sale' }]">
                    {{ item.format_type_special }}
                  </div>
                  <v-spacer />
                  <div v-if="item.count_sold_item !== 0" class="font-12">
                    {{ item.fcount_sold_item }} {{ $t('product.sold') }}
                  </div>
                </div>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ListProduct',
  props: {
    products: {
      required: true,
      type: Array
    },
    title: {
      default: '',
      type: String
    },
    funcShow: {
      required: true,
      type: Function
    },
    isRelated: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      cursorPos: [0, 0],
      el: null
    }
  },
  mounted() {
    window.addEventListener('mouseup', this.onMouseUp)
  },
  destroyed() {
    window.removeEventListener('mouseup', this.onMouseUp)
  },
  methods: {
    onMouseDown(ev) {
      this.cursorPos = [ev.pageX, ev.pageY]
      window.addEventListener('mousemove', this.onMouseHold)
    },
    onMouseUp(ev) {
      window.removeEventListener('mousemove', this.onMouseHold)
    },
    onMouseHold(ev) {
      ev.preventDefault()
      requestAnimationFrame(() => {
        const delta = [
          ev.pageX - this.cursorPos[0],
          ev.pageY - this.cursorPos[1]
        ]
        this.cursorPos = [ev.pageX, ev.pageY]
        if (!this.$refs.el) return
        this.$refs.el.scrollBy({
          left: -delta[0],
          top: -delta[1]
        })
      })
    },
    checkStock(item) {
      if (typeof item.items !== 'undefined') {
        if (item.is_out_stock) {
          return true
        }
      } else {
        if (item.is_out_stock) {
          return true
        } else if (item.has_variant && typeof item.variants !== 'undefined') {
          let isDisabled = true
          for (let i = 0; i < item.variants.length; i++) {
            const variant = item.variants[i]
            if (item.track_inventory) {
              const stock_incart = this.$stockCart(variant.id, 'variant')
              if (!variant.is_out_stock && Number(variant.hold_qty) + stock_incart < Number(variant.stock_qty)) {
                isDisabled = false
                break
              }
            } else {
              if (!variant.is_out_stock) {
                isDisabled = false
                break
              }
            }
          }
          return isDisabled
        } else {
          if (item.track_inventory) {
            const stock_incart = this.$stockCart(item.id)
            if (item.is_out_stock || stock_incart + Number(item.hold_qty) >= Number(item.stock_qty)) {
              return true
            }
          } else {
            if (item.is_out_stock) {
              return true
            }
          }
        }
      }
      return false
    }
  }
}
</script>
<style>
.wrap-list-product-related{
  contain: content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow-x: scroll;
  cursor: auto;
}
.wrap-list-product-related::-webkit-scrollbar-track,
.wrap-list-product-related::-webkit-scrollbar,
.wrap-list-product-related::-webkit-scrollbar-thumb {
  display: none;
}
.content-list-product-related{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  position: relative;
  white-space: nowrap;
  padding: 12px 12px 16px 12px;
}
.item-product-related{
  width: 250px;
  cursor: pointer;
}
.item-product-related:not(:last-child){
  margin-right: 24px;
}
</style>
